/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { PropertyModel } from '@app/model/PropertyModel';
import { completeFileDownload, formatPrice } from '@app/utils/utils';

export interface Props {
  data?: PropertyModel;
}

export const PropertySeenCard: React.FC<Props> = ({ data }) => {
  return (
    <Stack direction="row" sx={{ mb: 3 }}>
      <a href={`/property/${data?.uuid}`}>
        <img
          src={completeFileDownload(data?.images[0]?.downloadUrl)}
          alt={data?.title}
          style={{ width: 100, height: 80 }}
        />
      </a>
      <Box pl={1}>
        <Typography fontWeight={600} >{data?.title}</Typography>
        <Typography>{formatPrice(data?.price || 0)}</Typography>
      </Box>
    </Stack>
  );
};
