/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import IonIcon from '@reacticons/ionicons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, SwipeableDrawer } from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const HeaderMenu: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const onOpen = () => {
    console.log('opened');
  };

  return (
    <SwipeableDrawer anchor={'right'} open={open} onClose={onClose} variant="temporary" onOpen={onOpen}>
      <Box p={2}>
        <button onClick={onClose}>
          <IonIcon name="close-outline" size="large" />
        </button>
      </Box>
      <Stack sx={{ width: 300 }} className="navbar-bottom">
        <ul className="navbar">
          <li>
            <Accordion
              sx={{
                boxShadow: 'none',
              }}
            >
              <AccordionSummary aria-controls="panel1a-content">
                <a href="/" className="navbar-link p-0" data-nav-link>
                  {t('home')}
                </a>
              </AccordionSummary>
            </Accordion>
          </li>
          <li>
            <Accordion
              sx={{
                boxShadow: 'none',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                <a href="/acheter" className="navbar-link p-0" data-nav-link>
                  {t('acheter')}
                </a>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#c2c2c230' }}>
                <Stack spacing={3}>
                  <a href="/acheter" className="navbar-link p-0">
                    {t('acheter')}
                  </a>
                  <a href="/property" className="navbar-link p-0">
                    {t('my_properties')}
                  </a>
                  <a href="/acheter/calculatrice" className="navbar-link p-0">
                    {t('calculatrice_hypothecaire')}
                  </a>
                  <a href="/acheter/conseils" className="navbar-link p-0">
                    {t('conseil_acheteur')}
                  </a>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </li>
          <li>
            <Accordion
              sx={{
                boxShadow: 'none',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                <a href="/vendre" className="navbar-link p-0" data-nav-link>
                  {t('vendre')}
                </a>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#c2c2c230' }}>
                <Stack spacing={3}>
                  <a href="/vendre" className="navbar-link p-0">
                    {t('acheter')}
                  </a>
                  <a href="/vendre/demande-evaluation" className="navbar-link p-0">
                    {t('demande_evaluation')}
                  </a>
                  <a href="/vendre/conseils" className="navbar-link p-0">
                    {t('conseil_vendeur')}
                  </a>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </li>
          <li>
            <Accordion
              sx={{
                boxShadow: 'none',
              }}
            >
              <AccordionSummary aria-controls="panel1a-content">
                <a href="/investir" className="navbar-link p-0" data-nav-link>
                  {t('investir')}
                </a>
              </AccordionSummary>
            </Accordion>
          </li>
          <li>
            <Accordion
              sx={{
                boxShadow: 'none',
              }}
            >
              <AccordionSummary aria-controls="panel1a-content">
                <a href="/blog" className="navbar-link p-0" data-nav-link>
                  {t('blog')}
                </a>
              </AccordionSummary>
            </Accordion>
          </li>
          <li>
            <Accordion
              sx={{
                boxShadow: 'none',
              }}
            >
              <AccordionSummary aria-controls="panel1a-content">
                <a href="/volet-social" className="navbar-link p-0" data-nav-link>
                  {t('volet_social')}
                </a>
              </AccordionSummary>
            </Accordion>
          </li>
          <li>
            <Accordion
              sx={{
                boxShadow: 'none',
              }}
            >
              <AccordionSummary aria-controls="panel1a-content">
                <a href="/property" className="navbar-link p-0" data-nav-link>
                  {t('my_properties')}
                </a>
              </AccordionSummary>
            </Accordion>
          </li>
          <li>
            <Accordion
              sx={{
                boxShadow: 'none',
              }}
            >
              <AccordionSummary aria-controls="panel1a-content">
                <a href="/contact" className="navbar-link p-0" data-nav-link>
                  {t('contactme')}
                </a>
              </AccordionSummary>
            </Accordion>
          </li>
          <li>
            <Accordion
              sx={{
                boxShadow: 'none',
              }}
            >
              <AccordionSummary aria-controls="panel1a-content">
                <a href="/about" className="navbar-link p-0" data-nav-link>
                  {t('aboutus_me')}
                </a>
              </AccordionSummary>
            </Accordion>
          </li>
        </ul>
      </Stack>
    </SwipeableDrawer>
  );
};
