export const TemoignageData = [
    {
        name: 'Marie-Andrée',
        description: "Merci Nina pour ton dynamisme lors des négociations. Ton accompagnement a été très apprécié et indispensable tout au long du processus. Je suis très satisfaite de ton professionnalisme et d'avoir fait affaire avec toi! "
    },
    {
        name: 'Luc',
        description: "Bonjour Nina, une fois de plus, merci pour ton aide précieuse , tes précieux conseils et ton travail acharné qui ont facilité la finalisation de ma transaction. Tes réponses précises à mes questions ainsi que tes références à des professionnels efficaces m'ont apporté une tranquilité d'esprit tout au long de la transaction."
    },
    {
        name: 'Joyce',
        description: "Super service! Ta transparence a été pour moi une bouffée d'air frais. Étant à mon premier achat, tes conseils  et informations m'ont aider à prendre des décisions éclairées et ceci, sans me sentir bouscculée!"
    },
];