import React from 'react';
import IonIcon from '@reacticons/ionicons';
import { useTranslation } from 'react-i18next';

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="footer-brand">
            <a href="/" className="logo">
              <img src="/logo-light.png" alt="Nina Tchetgna logo" />
            </a>
            <p className="section-text">{t('courtier_immobilier_residentiel')}</p>
            <a
              href="https://vendirect.ca/courtier/michele-nina-tchetgna/134178/"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              <img src="/vendirect.png" alt="Vendirect logo" style={{ width: 200 }} />
            </a>
            <br />
            <br />
            <ul className="contact-list">
              <li>
                <a href="http://maps.google.com/?q=5-3055 boul. St-Martin Ouest, Laval Québec" className="contact-link">
                  <IonIcon name="location-outline" />
                  <address>5-3055 boul. St-Martin Ouest</address>
                </a>
              </li>

              <li>
                <a href="tel:+1438-308-7771" className="contact-link">
                  <IonIcon name="call-outline" />
                  <span>+1438-308-7771</span>
                </a>
              </li>

              {/* <li>
                <a href="tel:+1514-262-3131" className="contact-link">
                  <IonIcon name="call-outline" />
                  <span>+1514-262-3131</span>
                </a>
              </li> */}

              <li>
                <a href="mailto:info@ninatchetgna.com" className="contact-link">
                  <IonIcon name="mail-outline" />
                  <span>info@ninatchetgna.com</span>
                </a>
              </li>
            </ul>

            <ul className="social-list">
              <li>
                <a
                  href="https://www.facebook.com/nina.tchetgna"
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                >
                  <IonIcon name="logo-facebook" size='large' />
                </a>
              </li>

              <li>
                <a
                  href="https://www.linkedin.com/in/nina-tchetgna-47931416/"
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                >
                  <IonIcon name="logo-linkedin" size='large' />
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-link-box">
            <ul className="footer-list">
              <li>
                <p className="footer-list-title">Menu</p>
              </li>

              <li>
                <a href="/" className="footer-link">
                  {t('home')}
                </a>
              </li>

              <li>
                <a href="/acheter" className="footer-link">
                  {t('acheter')}
                </a>
              </li>

              <li>
                <a href="/vendre" className="footer-link">
                  {t('vendre')}
                </a>
              </li>

              <li>
                <a href="/investir" className="footer-link">
                  {t('investir')}
                </a>
              </li>

              <li>
                <a href="/property" className="footer-link">
                  {t('properties')}
                </a>
              </li>

              <li>
                <a href="/blog" className="footer-link">
                  {t('blog')}
                </a>
              </li>

              <li>
                <a href="/about" className="footer-link">
                  {t('aboutus')}
                </a>
              </li>
            </ul>

            <ul className="footer-list">
              <li>
                <p className="footer-list-title">Services</p>
              </li>

              <li>
                <a href="/acheter" className="footer-link">
                  {t('acheter')}
                </a>
              </li>

              <li>
                <a href="/vendre" className="footer-link">
                  {t('vendre')}
                </a>
              </li>

              <li>
                <a href="/investir" className="footer-link">
                  {t('investir')}
                </a>
              </li>
            </ul>

            <ul className="footer-list">
              <li>
                <p className="footer-list-title">SAV</p>
              </li>

              <li>
                <a href="/faqs" className="footer-link">
                  FAQ
                </a>
              </li>

              <li>
                <a href="/contact" className="footer-link">
                  Contactez-moi
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <p className="copyright">
            &copy; <a href="https://www.cybexai.com/"
                  target="_blank"
                  rel="noreferrer">cybexai</a>. All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};
