import React from 'react';
import IonIcon from '@reacticons/ionicons';
import { useTranslation } from 'react-i18next';

export const Programme: React.FC = () => {
  const { t } = useTranslation();
  const programmes = ['/association.png', '/protection.png', '/satisfaction.png'];
  return (
    <section className="features">
      <div className="container">
        {/* <p className="section-subtitle">Programmes Vendirect</p> */}

        <h2 className="h2 section-title">{t('programme_vendirect')}</h2>

        <ul className="features-list">
          {programmes.map((programme: string) => (
            <li key={programme} data-aos="fade-right">
              <a
                href="https://vendirect.ca/vendre-une-propriete/"
                target="_blank"
                rel="noreferrer"
                className="features-card"
              >
                {/* <div className="card-icon"> */}
                <img src={programme} alt="programme" className="features-img" />
                {/* </div> */}

                {/* <h3 className="card-title">Parking Space</h3> */}

                <div className="card-btn">
                  <IonIcon name="arrow-forward-outline" className="ion-icon-header" />
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
