import { BASE_URL } from './Consts';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export function getObjectKeys<T>(obj: T): Array<keyof T> {
  return Object.keys(obj as any) as Array<keyof T>;
}

export function getYears() {
  const currentYear = new Date().getFullYear();

  // Create an array of years from 1940 to the current year
  const yearsArray = [];
  for (let year = 1940; year <= currentYear; year++) {
    yearsArray.push(year);
  }
  return yearsArray;
}

export function setUndefinedToEmptyString(obj: any) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === undefined) {
        obj[key] = '';
      }
    }
  }
  return obj;
}

export function ensureHttps(url: string): string {
  // Check if the URL starts with "https://"
  if (!url.startsWith('https://')) {
    // If not, add "https://"
    return `https://${url}`;
  }

  // If it already starts with "https://", return the original URL
  return url;
}

export function renameFileDownload(filename: string) {
  return filename.replace('ninatchetgna/api', 'api').replace('http://', 'https://');
}

export function completeFileDownload(filename: string) {
  return `${BASE_URL.replace('api/', '')}${filename}`;
}

export function calculateMortgage(amount: number, interest: number, duration: number) {
  const monthlyInterestRate = interest / 12 / 100;
  const numberOfPayments = duration * 12;

  // let paymentFrequency;
  // let numberOfPaymentsPerYear;

  // switch (paymentType) {
  //   case 'MENSUEL':
  //     paymentFrequency = 12;
  //     numberOfPaymentsPerYear = 12;
  //     break;
  //   case 'HEBDOMADAIRE':
  //     paymentFrequency = 52;
  //     numberOfPaymentsPerYear = 52;
  //     break;
  //   case 'BIHEBDOMADAIRE':
  //     paymentFrequency = 26;
  //     numberOfPaymentsPerYear = 26;
  //     break;
  //   default:
  //     throw new Error('Invalid payment type. Use "monthly", "weekly", or "biweekly".');
  // }

  // const monthlyPayment =
  //   ((amount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments))) *
  //   (paymentFrequency / numberOfPaymentsPerYear);

  // const annualPayment = monthlyPayment * numberOfPaymentsPerYear;

  const monthlyPayment = amount * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
  (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

  const annualPayment = monthlyPayment * 12;

  return {
    monthlyPayment: monthlyPayment,
    annualPayment: annualPayment,
  };
}

export const displayInputValue = (value: string | number) => {
  if (Number(value) === 0) {
    return '';
  }
  return String(value);
};

export function stringReduceAddDots(string: string, limit: number) {
  if (String(string).length <= limit) {
    return string;
  } else {
    return `${String(string).slice(0, limit)}...`;
  }
}

export function getPropertyStatusColor(status: string) {
  switch (status) {
    case 'SELL':
      return 'green';
    case 'RENT':
      return 'green';
    case 'SOLD':
      return 'blue';
    case 'RENTED':
      return 'blue';

    default:
      return 'green';
  }
}

export const formatPrice = (price: number): string => {
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
