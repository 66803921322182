import React, { useEffect } from 'react';
// import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
// import frFR from 'antd/lib/locale/fr_FR';
// import enUS from 'antd/lib/locale/en_US';
import AOS from 'aos';
import 'aos/dist/aos.css';
import GlobalStyle from './styles/GlobalStyle';
import { AppRouter } from './components/router/AppRouter';

const App: React.FC = () => {
  // const { language } = useLanguage();
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
      <GlobalStyle />
      <HelmetProvider>
        <AppRouter />
      </HelmetProvider>
    </>
  );
};

export default App;
