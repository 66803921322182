import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Footer } from '../HomePages/components/Footer';
import { Grid, Stack, Typography } from '@mui/material';
import { CustomTextField } from '@app/components/text/CustomTextField';
import { useDispatch } from 'react-redux';
import { createAsyncContact } from '@app/store/slices/contactsSlice';
import { Snackbar } from '@mui/joy';
import { ContactModel } from '@app/model/ContactModel';
import { LoaderModal } from '@app/components/LoaderModal';
import { displayInputValue } from '@app/utils/utils';

const ContactPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifMessage, setNotifMessage] = useState('');
  const [formData, setFormData] = useState<ContactModel>({
    surname: '',
    name: '',
    email: '',
    address: '',
    postalcode: '',
    phonenumber: 0,
    town: '',
    message: '',
  });

  const callback = () => {
    setNotifMessage(t('save_success'));
    setLoading(false);
    setOpen(true);
    resetFields();
  };
  const onError = () => {
    setNotifMessage(t('error_message'));
    setLoading(false);
    setOpen(true);
  };

  const resetFields = () => {
    setFormData({
      surname: '',
      name: '',
      email: '',
      address: '',
      postalcode: '',
      phonenumber: 0,
      town: '',
      message: '',
    });
    window.scrollTo(0, 0);
  };

  const onChangeText = (valKey: string, text: string) => {
    setFormData({ ...formData, [valKey]: text });
  };

  const onSave = () => {
    if (
      formData.surname === '' ||
      formData.name === '' ||
      formData.email === '' ||
      formData.address === '' ||
      formData.postalcode === '' ||
      formData.phonenumber === 0 ||
      formData.town === '' ||
      formData.message === ''
    ) {
      setNotifMessage(t('error_fields'));
      setOpen(true);
      return;
    }
    setLoading(true);
    dispatch(createAsyncContact(formData, callback, onError));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle>{t('contact')}</PageTitle>
      <Header />
      <main className="greybg">
        <div className="overlay-container" style={{ background: "url('/contact.jpg') center/cover" }}>
          <div className="overlay-header">
            <h2 className="h2 section-title">{t('contactme')}</h2>
          </div>
        </div>

        <Grid container className="container" spacing={5} my={5}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" fontWeight={550} mb={1}>{`Information Personnelle`}</Typography>
            <p className="text-danger">{`Veuillez nous fournir vos coordonnées en remplissant 
            ce formulaire et nous vous contacterons dès que possible.`}</p>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <CustomTextField
                title="Prénom"
                value={formData.surname}
                onChange={(val: string) => onChangeText('surname', val)}
              />
              <CustomTextField
                title="Courriel"
                value={formData.email}
                onChange={(val: string) => onChangeText('email', val)}
              />
              <CustomTextField
                title="Adresse"
                value={formData.address}
                onChange={(val: string) => onChangeText('address', val)}
              />
              <CustomTextField
                title="Code postal"
                value={formData.postalcode}
                onChange={(val: string) => onChangeText('postalcode', val)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <CustomTextField
                title="Nom"
                value={formData.name}
                onChange={(val: string) => onChangeText('name', val)}
              />
              <CustomTextField
                title="Téléphone"
                value={displayInputValue(formData.phonenumber)}
                onChange={(val: string) => onChangeText('phonenumber', val.replace(/[^0-9]/g, ''))}
              />
              <CustomTextField
                title="Ville"
                value={formData.town}
                onChange={(val: string) => onChangeText('town', val)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              title="Message"
              value={formData.message}
              multiline
              rows={5}
              onChange={(val: string) => onChangeText('message', val)}
            />
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={{ xs: 'center', md: 'flex-end' }}>
            <button className="btn center" onClick={onSave}>
              {t('send')}
            </button>
          </Grid>
        </Grid>
        {/* <div className="service-card">
          <Grid container className="container" spacing={5} py={5}>
            <Grid item xs={12} md={6}>
              <div>
                <FacebookProvider appId="1206026023689564">
                  <Page href="https://www.facebook.com/cybexai" tabs="timeline" />
                </FacebookProvider>
              </div>
            </Grid>
          </Grid>
        </div> */}
        <Snackbar
          autoHideDuration={4000}
          open={open}
          variant={'solid'}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          color={'success'}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setOpen(false);
          }}
        >
          <Typography>{notifMessage}</Typography>
        </Snackbar>
        {loading && <LoaderModal open={loading} />}
        <Footer />
      </main>
    </>
  );
};

export default ContactPage;
