/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { PropertyModel } from '@app/model/PropertyModel';
import { getPropertiesApi, getPropertyApi, getSimilarPropertyApi } from '@app/api/property.api';
import { AppDispatch } from '../store';

const initialState: Record<string, PropertyModel> = {};

export const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    listProperty: (state, action) => {
      state = {};
      for (const obj of action.payload) {
        state[obj.uuid] = obj;
      }
      return state;
    },
  },
});

const { listProperty } = propertySlice.actions;

export const listAsyncProperties = () => async (dispatch: AppDispatch) => {
  try {
    const res = await getPropertiesApi();
    dispatch(listProperty(res));
  } catch (error) {
    console.log('list properties error', error);
  }
};

export const getProperty = async (uuid: string) => {
  try {
    const result = await getPropertyApi(uuid);
    return result;
  } catch (error) {
    console.log('get property error', error);
  }
};

export const getSimilarProperty = async (uuid: string) => {
  try {
    const result = await getSimilarPropertyApi(uuid);
    return result;
  } catch (error) {
    console.log('get similar property error', error);
  }
};

export default propertySlice.reducer;
