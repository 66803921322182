import React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';
import { TemoignageData } from '@app/mocks/temoignageData';
import { Box, Stack, Typography } from '@mui/material';

export const Temoignage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box data-aos="fade-up" className="overlay-container" style={{ background: "url('social.png') center/cover" }}>
      <div className="overlay-header" style={{ background: 'rgba(112, 123, 177, 0.7)' }}>
        <br />
        <Stack alignItems={'center'}>
          <h2 className="h2 section-title">{t('temoignages')}</h2>
        </Stack>

        <Carousel sx={{ width: '70%' }}>
          {TemoignageData.map((item, i) => (
            <div className="service-card" style={{ border: 'none' }} key={i}>
              <p className="card-text">{item.description}</p>

              <Typography color={'hsl(180, 20%, 62%)'} fontWeight={600}>
                <span>{item.name}</span>
              </Typography>
            </div>
          ))}
        </Carousel>
        <br />
      </div>
    </Box>
  );
};
