import React, { CSSProperties } from 'react';
import { Divider, Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  titleStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  showTopDivider?: boolean;
}

export const PropertyDetailTitle: React.FC<Props> = ({ title, titleStyle, containerStyle, showTopDivider }) => {
  return (
    <Stack spacing={2} sx={{ ...containerStyle }}>
      {showTopDivider && <Divider />}
      <Typography sx={{ fontWeight: 600, ...titleStyle }}>{title}</Typography>
      <Divider />
    </Stack>
  );
};
