/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../store';
import { getBlogApi, getBlogsApi } from '@app/api/blog.api';

// const initialState: Record<string, BlogModel> = [];

export const blogSlice = createSlice({
  name: 'blog',
  initialState: [],
  reducers: {
    listBlog: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

const { listBlog } = blogSlice.actions;

export const listAsyncBlogs = () => async (dispatch: AppDispatch) => {
  try {
    const res = await getBlogsApi();
    dispatch(listBlog(res));
  } catch (error) {
    console.log('list blogs error', error);
  }
};

export const getBlog = async (uuid: string) => {
  try {
    const result = await getBlogApi(uuid);
    return result;
  } catch (error) {
    console.log('get blog error', error);
  }
};

export default blogSlice.reducer;
