/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Footer } from '../HomePages/components/Footer';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CustomKeyValue } from '@app/components/text/CustomKeyValue';
import { COLORS } from '@app/utils/Consts';
import { PropertyDetailTitle } from '@app/components/text/PropertyDetailTitle';
import { PropertySeenCard } from '@app/components/PropertySeenCard';
import { PropertySimilarCard } from '@app/components/PropertySimilarCard';
// import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { PropertyModel } from '@app/model/PropertyModel';
import { getProperty, getSimilarProperty } from '@app/store/slices/propertySlice';
import { completeFileDownload, formatPrice } from '@app/utils/utils';
import { Table } from '@mui/joy';
import { DetailAccordion } from '@app/components/DetailAccordion';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const PropertyDetailPage: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [data, setData] = useState<PropertyModel>();
  const [dataSimilar, setSimilarData] = useState<PropertyModel[]>();
  const seenProperties = JSON.parse(localStorage.getItem('seenProperties') || '[]');

  const [open, setOpen] = React.useState(false);
  // const [currentImage, setCurrentImage] = React.useState(0);
  const sliderRef = React.useRef<Slider | null>(null) as any;

  const images = data?.images?.map((image) => completeFileDownload(image?.downloadUrl)) || [];

  const pieces = Object.values(JSON.parse(data?.pieces || '{}') || {});

  const settings = {
    customPaging: function (i: any) {
      return (
        <a style={{ marginRight: 5 }}>
          <img src={images[i]} alt={`Paging Indicator ${i + 1}`} style={{ height: '80px' }} />
        </a>
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // afterChange: (currentSlide: number) => setCurrentImage(currentSlide),
  };

  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const handleFullscreenToggle = () => {
  //   if (document.fullscreenElement) {
  //     document.exitFullscreen();
  //   } else {
  //     sliderRef.current?.parentNode?.requestFullscreen();
  //   }
  // };

  const addAlreadySeen = useCallback(
    (item?: PropertyModel) => {
      if (!seenProperties.filter((val: PropertyModel) => val.uuid === item?.uuid).length) {
        const localSeenProperties = [...seenProperties, item];
        localStorage.setItem('seenProperties', JSON.stringify(localSeenProperties));
      }
    },
    [seenProperties],
  );

  // const removeAlreadySeen = (itemID: string) => {
  //   let seenProperties = JSON.parse(localStorage.getItem('seenProperties') || '[]');
  //   seenProperties = seenProperties.filter((val: PropertyModel) => val.uuid !== itemID);
  //   localStorage.setItem('seenProperties', JSON.stringify(seenProperties));
  // };

  const getAsyncProperty = useCallback(async () => {
    const res = await getProperty(params?.id || '');
    const resultSimilar = await getSimilarProperty(params?.id || '');
    setData(res);
    setSimilarData(resultSimilar);
    addAlreadySeen(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    getAsyncProperty();
    window.scrollTo(0, 0);
  }, [getAsyncProperty]);

  return (
    <>
      <PageTitle>{t('my_properties')}</PageTitle>
      <Header />
      <main className="greybg">
        <Box className="slider-container">
          <Carousel showStatus={false} showIndicators={false}>
            {images.map((image, index) => (
              <Box height={550} key={index}>
                <img src={image} style={{ width: '100%', height: '100%' }} alt={`Slide ${index}`} />
              </Box>
            ))}
          </Carousel>
          {/* <IconButton color="primary" onClick={handleOpen}>
            {document.fullscreenElement ? <FullscreenExit /> : <Fullscreen />}
          </IconButton> */}
        </Box>

        <section style={{ paddingTop: 20 }} className="blog">
          <div className="container">
            <Grid spacing={4} container>
              <Grid xs={12} md={8} item>
                <Typography fontWeight={600}>{data?.title}</Typography>
                <Typography fontWeight={600} mt={2}>
                  {formatPrice(data?.price || 0)}
                </Typography>
              </Grid>
              <Grid xs={12} md={8} item>
                <Tabs
                  variant="outlined"
                  aria-label="Pricing plan"
                  defaultValue={0}
                  sx={{
                    borderRadius: 'md',
                    boxShadow: 'sm',
                    overflow: 'auto',
                  }}
                >
                  <TabList
                    disableUnderline
                    tabFlex={1}
                    sx={{
                      [`& .${tabClasses.root}`]: {
                        fontSize: 'sm',
                        fontWeight: 'lg',
                        [`&[aria-selected="true"]`]: {
                          color: COLORS.primary,
                          bgcolor: 'background.surface',
                        },
                        [`&.${tabClasses.focusVisible}`]: {
                          outlineOffset: '-4px',
                        },
                      },
                      height: 60,
                    }}
                  >
                    <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                      Description
                    </Tab>
                    <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                      Adresse
                    </Tab>
                    <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                      Détails
                    </Tab>
                    <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                      Évaluation & Taxes
                    </Tab>
                  </TabList>
                  <TabPanel value={0}>
                    <PropertyDetailTitle title="Description" />
                    <Typography p={3}>{data?.description || '--'}</Typography>
                    <PropertyDetailTitle title="Addenda" showTopDivider />
                    <Typography p={3}>{data?.addenda || '--'}</Typography>
                  </TabPanel>
                  <TabPanel value={1}>
                    <Grid container>
                      <Grid xs={12} p={1} item>
                        <CustomKeyValue title="Adresse:" value={data?.address} />
                      </Grid>
                    </Grid>
                    <Box mt={3}>
                      <iframe
                        src={`https://www.google.com/maps/embed/v1/place?q=${data?.address}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`}
                        width={'100%'}
                        height={450}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </Box>
                  </TabPanel>
                  <TabPanel value={2}>
                    <DetailAccordion title="TITRE">
                      <Grid container my={3}>
                        <Grid xs={12} md={6} p={1} item>
                          <CustomKeyValue direction="column" title="Titre:" value={data?.title || '--'} />
                        </Grid>
                        <Grid xs={12} md={6} p={1} item>
                          <CustomKeyValue direction="column" title="Adresse:" value={data?.address || '--'} />
                        </Grid>
                        <Grid xs={12} md={6} p={1} item>
                          <CustomKeyValue direction="column" title="Prix:" value={formatPrice(data?.price || 0)} />
                        </Grid>
                        <Grid xs={12} md={6} p={1} item>
                          <CustomKeyValue direction="column" title="Statut:" value={t(data?.status || 'RENT')} />
                        </Grid>
                      </Grid>
                    </DetailAccordion>

                    <DetailAccordion title="PARTICULARITÉS DU BÂTIMENT">
                      <Grid container my={3}>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue
                            direction="column"
                            title="Type de bâtiment:"
                            value={t(data?.typeBatiment || '')}
                          />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue
                            direction="column"
                            title="Nombre de chambres:"
                            value={data?.numChambre?.toString() || '--'}
                          />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue
                            direction="column"
                            title="Nombre de salles de bains:"
                            value={data?.numSalleBain?.toString() || '--'}
                          />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue
                            direction="column"
                            title="Nombre de salles d'eau:"
                            value={data?.numSalleEau?.toString() || '--'}
                          />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue
                            direction="column"
                            title="Nombre de pièces:"
                            value={data?.numPiece?.toString() || '--'}
                          />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Dimensions:" value={data?.dimensionBatiment || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue
                            direction="column"
                            title="Année de construction:"
                            value={data?.anneeConstruction.toString() || '--'}
                          />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Fenestration:" value={data?.fenetre || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Type de fenestration:" value={data?.typeFenetre || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Fondation:" value={data?.fondation || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Revêtement:" value={data?.revetement || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Revêtement de la toiture:" value={data?.toiture || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Garage:" value={data?.abriAuto || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Armoires de cuisine:" value={data?.armoire || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Sous-sol:" value={data?.soussol || '--'} />
                        </Grid>
                      </Grid>
                    </DetailAccordion>

                    <DetailAccordion title="PARTICULARITÉS DU TERRAIN">
                      <Grid container my={3}>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue
                            direction="column"
                            title="Superficie du terrain:"
                            value={data?.superficieTerrain || '--'}
                          />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue
                            direction="column"
                            title="Approvisionnement en eau:"
                            value={data?.approvisionnementEau || '--'}
                          />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Terrain:" value={data?.terrain || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Topographie:" value={data?.topographie || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Vue:" value={data?.abriAuto || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Allée:" value={data?.allee || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Stationnement:" value={data?.stationnement || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Proximité:" value={data?.proximite || '--'} />
                        </Grid>
                      </Grid>
                    </DetailAccordion>

                    <DetailAccordion title="DÉTAILS DES PIÈCES">
                      <Grid container my={3}>
                        <Grid xs={12} p={1} item>
                          <Table aria-label="striped table" stripe={'even'}>
                            <thead>
                              <tr>
                                <th>Pièces</th>
                                <th>Niveau</th>
                                <th>Dimensions</th>
                                <th>Revêtement</th>
                                <th>Détails</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pieces.map((piece: any, inx) => (
                                <tr key={inx}>
                                  <td>{piece.piece}</td>
                                  <td>{piece.niveau}</td>
                                  <td>{piece.dimension}</td>
                                  <td>{piece.revetement}</td>
                                  <td>{piece.detail}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Grid>
                      </Grid>
                    </DetailAccordion>

                    <DetailAccordion title="CARACTÉRISTIQUES">
                      <Grid container my={3}>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Mode de chauffage:" value={data?.modeChauffage || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue
                            direction="column"
                            title="Énergie pour le chauffage:"
                            value={data?.energieChauffage || '--'}
                          />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Foyer-Poêle:" value={data?.foyerPoele || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Équipement:" value={data?.equipmentDisponible || '--'} />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue
                            direction="column"
                            title="Approvisionnement en eau:"
                            value={data?.approvisionnementEau || '--'}
                          />
                        </Grid>
                        <Grid xs={12} md={4} p={1} item>
                          <CustomKeyValue direction="column" title="Système d'égouts:" value={data?.systemeEgout || '--'} />
                        </Grid>
                      </Grid>
                    </DetailAccordion>

                    <DetailAccordion title="INCLUSIONS & EXCLUSIONS">
                      <Grid container my={3}>
                        <Grid xs={12} md={6} p={1} item>
                          <CustomKeyValue direction="column" title="Inclusions:" value={data?.inclusion || '--'} />
                        </Grid>
                        <Grid xs={12} md={6} p={1} item>
                          <CustomKeyValue direction="column" title="Exclusions:" value={data?.exclusion || '--'} />
                        </Grid>
                      </Grid>
                    </DetailAccordion>
                  </TabPanel>
                  <TabPanel value={3}>
                    <PropertyDetailTitle title="Évaluations, Taxes et Dépenses" />
                    <Grid container my={3}>
                      <Grid xs={12} md={6} p={1} item>
                        <CustomKeyValue direction="column" title="Année:" value={data?.year?.toString() || '--'} />
                      </Grid>
                      <Grid xs={12} md={6} p={1} item>
                        <CustomKeyValue
                          direction="column"
                          title="Évaluation de la bâtisse:"
                          value={formatPrice(Number(data?.evalBatisse?.replace(' ', '') || '0'))}
                        />
                      </Grid>
                      <Grid xs={12} md={6} p={1} item>
                        <CustomKeyValue
                          direction="column"
                          title="Évaluation du terrain:"
                          value={formatPrice(Number(data?.evalTerrain?.replace(' ', '') || '0'))}
                        />
                      </Grid>
                      <Grid xs={12} md={6} p={1} item>
                        <CustomKeyValue
                          direction="column"
                          title="Taxes municipales:"
                          value={formatPrice(Number(data?.taxeMuni?.replace(' ', '') || '0'))}
                        />
                      </Grid>
                      <Grid xs={12} md={6} p={1} item>
                        <CustomKeyValue
                          direction="column"
                          title="Taxes scolaires:"
                          value={formatPrice(Number(data?.taxeScolaire?.replace(' ', '') || '0'))}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Tabs>
                {dataSimilar?.length ? (
                  <Box
                    sx={{
                      p: 2,
                      mt: 4,
                      borderRadius: 2,
                      bgcolor: 'white',
                    }}
                  >
                    <PropertyDetailTitle title="Propriétés similaires" containerStyle={{ marginBottom: 4 }} />
                    {dataSimilar?.map((val: PropertyModel) => (
                      <PropertySimilarCard key={val?.uuid} data={data} />
                    ))}
                  </Box>
                ) : null}
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ bgcolor: 'white', p: 2, borderRadius: 2 }}>
                  <Typography fontWeight={600} mb={3}>
                    Vu Récemment
                  </Typography>
                  {seenProperties?.map((val: PropertyModel) => (
                    <PropertySeenCard key={val?.uuid} data={val} />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </div>
        </section>
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
          <DialogTitle>
            <Typography variant="h6">Full-Screen Image</Typography>
          </DialogTitle>
          <DialogContent>
            <Slider {...settings} ref={sliderRef}>
              {images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={`Slide ${index}`} style={{ width: '100%' }} />
                </div>
              ))}
            </Slider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Footer />
      </main>
    </>
  );
};

export default PropertyDetailPage;
