import React from 'react';
import IonIcon from '@reacticons/ionicons';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

export const BannerContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack alignItems={{md: 'flex-end', xs: 'center'}} className="hero-content">
      <ul className="contact-list">
        <li>
          <h2 className="h2 hero-title">Nina Tchetgna</h2>
        </li>
        <li>
          <p className="hero-subtitle white bold">
            <span>{t('courtier_immobilier_residentiel')}</span>
          </p>
        </li>
        <li>
          <a href="tel:+1438-308-7771" className="contact-link white bold">
            <IonIcon name="call" />
            <span>+1438-308-7771</span>
          </a>
        </li>

        <li>
          <a href="mailto:info@ninatchetgna.com" className="contact-link white bold">
            <IonIcon name="mail" />
            <span>info@ninatchetgna.com</span>
          </a>
        </li>
        <li>
          <ul className="header-top-social-list">
            <li>
              <a
                href="https://www.facebook.com/nina.tchetgna"
                target="_blank"
                rel="noreferrer"
                className="header-top-social-link"
              >
                <IonIcon name="logo-facebook" size="large" className="ion-icon-header white" />
              </a>
            </li>

            <li>
              <a
                href="https://www.linkedin.com/in/nina-tchetgna-47931416/"
                target="_blank"
                rel="noreferrer"
                className="header-top-social-link"
              >
                <IonIcon name="logo-linkedin" size="large" className="ion-icon-header white" />
              </a>
            </li>
          </ul>
        </li>

        <li>
          <br />

          <Stack className="vendirect-header">
            <a
              href="https://vendirect.ca/courtier/michele-nina-tchetgna/134178/"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              <img src="/vendirect.png" alt="Vendirect logo" />
            </a>
          </Stack>
        </li>
      </ul>

      {/* <h2 className="h3 hero-title">{t('find_dream_house')}</h2> */}

      {/* <p className="hero-text">{t('find_dream_house_desc')}</p> */}
    </Stack>
  );
};
