/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Footer } from '../HomePages/components/Footer';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { getBlog } from '@app/store/slices/blogSlice';
import { BlogModel } from '@app/model/BlogModel';
import { renameFileDownload } from '@app/utils/utils';

const BlogDetailPage: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [data, setData] = useState<BlogModel>();

  const getAsyncBlog = useCallback(async () => {
    const res = await getBlog(params?.id || '');
    setData(res);
  }, [params?.id]);

  useEffect(() => {
    getAsyncBlog();
    window.scrollTo(0, 0);
  }, [getAsyncBlog]);

  return (
    <>
      <PageTitle>{t('blog')}</PageTitle>
      <Header />
      <main className="greybg">
        <div className="overlay-container" style={{ background: `url(${renameFileDownload(data?.imageUrl || '')}) center/cover` }}>
          <div className="overlay-header">
            <h2 className="h4 section-title">{data?.title}</h2>
          </div>
        </div>
        <section className="blog" id="blog">
          <div className="container">
            {/* <p className="section-subtitle">{t('blog')}</p>

            <h2 className="h2 section-title">{data?.title}</h2> */}
            <Stack alignItems={'center'} key={data?.uuid}>
              <Card>
                {/* <CardMedia component="img" height="500" image={data?.image} alt="blog title" /> */}
                <CardContent sx={{ p: 5 }}>
                  {/* <Typography gutterBottom variant="h5" component="div">
                      {data?.title}
                    </Typography> */}
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: data?.description || '',
                    }}
                    color="text.secondary"
                  ></Typography>
                </CardContent>
              </Card>
            </Stack>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default BlogDetailPage;
