/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Footer } from '../HomePages/components/Footer';
import { Grid } from '@mui/material';
import { PropertyCard } from '@app/components/PropertyCard';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { PropertyModel } from '@app/model/PropertyModel';

const PropertyPage: React.FC = () => {
  const properties = useAppSelector((state) => Object.values(state.property));

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle>{t('my_properties')}</PageTitle>
      <Header />
      <main>
        <div className="overlay-container" style={{ background: "url('/my-properties.webp') center/cover" }}>
          <div className="overlay-header">
            <h2 className="h2 section-title">{t('my_properties')}</h2>
          </div>
        </div>
        <section className="blog">
          <div className="container">
            <Grid container spacing={5}>
              {properties.map((data: PropertyModel) => (
                <Grid item key={data.uuid} md={4} xs={12}>
                  <PropertyCard data={data} />
                </Grid>
              ))}
            </Grid>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default PropertyPage;
