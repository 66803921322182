/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { Footer } from '../HomePages/components/Footer';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { ConseilModel } from '@app/model/ConseilModel';
import { FilePreviewModal } from '@app/components/FilePreviewModal';
import { renameFileDownload } from '@app/utils/utils';

const ConseilVendeurPage: React.FC = () => {
  const conseils = useAppSelector((state) => Object.values(state.conseil).filter((val) => val.type === 'VENDRE'));
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [fileUrl, setFileUrl] = useState('');

  const openFile = (url: string) => {
    setFileUrl(url);
    setVisible(true);
  };

  const closeFile = () => {
    setVisible(false);
    setFileUrl('');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle>{t('conseil_vendeur')}</PageTitle>
      <Header />
      <main className="greybg">
        <div className="overlay-container" style={{ background: "url('/conseil-vendeur.jpg') center/cover" }}>
          <div className="overlay-header">
            <h2 className="h2 section-title">{t('conseil_vendeur')}</h2>
          </div>
        </div>
        <Stack my={5} className="container">
          <Stack alignItems={'center'} mt={3} mb={8}>
            <Typography variant="body1" width={{ md: '50%', xs: '100%' }} textAlign={'center'}>
              {`
              Vous trouverez ci-dessous quelques ressources importantes pour vous aider dans votre projet de vente de votre propriété.
              `}
            </Typography>
          </Stack>

          {conseils.map((val: ConseilModel) => (
            <Accordion key={val.title}>
              <AccordionSummary
                // sx={{ bgcolor: COLORS.primary + '50' }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{val.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {val.imageUrl ? (
                 <Stack spacing={3} alignItems={'center'} width={'150px'} mb={3}>
                 <img
                   src="/document.png"
                   alt="Document"
                   onClick={() => openFile(val?.imageUrl || '')}
                   style={{ width: 150, cursor: 'pointer' }}
                 />
                 <a href={renameFileDownload(val?.imageUrl || '').replace('/ninatchetgna', '')}  className='btn'>{t('download')}</a>
               </Stack>
                ) : null}
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: val.description,
                  }}
                ></Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
        {visible && (
          <FilePreviewModal
            open={visible}
            docUrl={renameFileDownload(fileUrl).replace('/ninatchetgna', '')}
            onClose={closeFile}
          />
        )}
        <Footer />
      </main>
    </>
  );
};

export default ConseilVendeurPage;
