/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Footer } from '../HomePages/components/Footer';

const InvestirPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToContact = () => {
    navigate('/contact');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle>{t('investir')}</PageTitle>
      <Header />
      <main className="greybg">
        <div className="overlay-container" style={{background: "url('/invest.webp') center/cover"}}>
          <div className="overlay-header">
            <h2 className="h2 section-title">{'Investir En Immobilier'}</h2>
          </div>
        </div>
        <section className="container">
          <Stack alignItems={'center'} my={10}>
            <Typography variant="body1" width={{md:'50%', xs: '100%'}} textAlign={'center'}>
              {`Lorsqu'il s'agit d'augmenter ses revenus, de nombreuses options existent!  L'investissement immobilier  est un choix optimal qui combine stabilité, potentiel de rentabilité et simplicité! Les bénéfices de l'immobilier se démarquent clairement  de toutes les autres options.    Alors, que vous soyez un débutant ou un investisseur aguéri, nous mettons nos connaissances en évaluation de la rentabilité des immeubles à votre service!`}
            </Typography>
          </Stack>
        </section>
        <section className="features" style={{ background: 'white', paddingTop: 0 }}>
          <Stack alignItems={'center'}>
          <img src="/inves.png" className="acheter-engage" />
          </Stack>

          <Stack alignItems={'center'}>
            <Typography variant="body1" mb={3} width={{ md: '50%', xs: '100%' }} textAlign={'center'}>
              {`Communiquer avec nous et il nous fera plaisir de vous accompagner dans votre projet d'investissement!`}
            </Typography>
            <button className="btn center" onClick={goToContact}>{t('contactme')}</button>
          </Stack>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default InvestirPage;
