/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Footer } from '../HomePages/components/Footer';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const VendrePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToEvaluation=()=> {
    navigate('/vendre/demande-evaluation');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle>{t('vendre')}</PageTitle>
      <Header />
      <main className="greybg">
        <div className="overlay-container" style={{background: "url('/buy.jpg') center/cover"}}>
          <div className="overlay-header">
            <h2 className="h2 section-title">{'Vendre Votre Propriété'}</h2>
          </div>
        </div>
        <section className="container">
          <Stack alignItems={'center'} spacing={5} my={10}>
            <Typography variant="body1" width={{ md: '50%', xs: '100%' }} textAlign={'center'}>
              {`La vente de votre propriété peut être une expérience accablante si vous n'êtes pas bien accompagné. Nous vous offrons des ressources pertinentes, des conseils judicieux ainsi qu’une visibilité hors pair pour votre propriété.  Aussi, nous vous aidons à traverser toutes les étapes du processus de vente en vue de vous offrir une transaction réussie !`}
            </Typography>
            <button className="btn center" onClick={goToEvaluation}>{t('demande_evaluation')}</button>
          </Stack>
        </section>
        <section className="features" style={{ background: 'white', paddingTop: 0 }}>
          <Stack alignItems={'center'}>
          <img src="/engage.png" className="acheter-engage" />
            <h2 className="h2 section-title">{'Notre Engagement Envers Vous'}</h2>
          </Stack>

          <Stack alignItems={'center'} spacing={5} mb={5}>
            <Typography variant="body1" width={{ md: '50%', xs: '100%' }} textAlign={'center'}>
              {`Nous travaillons pour VOS INTÉRÊTS! En effet, vous êtes au centre de toute la démarche, votre paix d'esprit ainsi que votre confiance sont prioritaires pour nous.  Nous sommes présents pour vous à chaque étape! Nous vous accompagnons tout au long du processus par nos conseils avec l'aide des partenaires qualifiés. Votre satisfaction est notre récompense! Contribuer à ce que votre transaction se fasse dans la paix d'esprit et que vous obtenez le montant juste, voilà ce pourquoi nous travaillons avec acharnement!`}
            </Typography>
          </Stack>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default VendrePage;
