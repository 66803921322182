import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Mission: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box mb={15}>
      <div className="container">
        <div className="cta-card" data-aos="fade-up">
          <div className="card-content">
            <h2 className="h2 card-title center">{t('my_mission')}</h2>

            <p className="card-text center">{t('my_mission_desc')}</p>
          </div>

          {/* <button className="btn cta-btn" onClick={goToProperties}>
            <span>{t('explore_properties')}</span>
            <IonIcon name="arrow-forward-outline" className="ion-icon-header" />
          </button> */}
        </div>
      </div>
    </Box>
  );
};
