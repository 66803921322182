import React from 'react';
import { BannerContent } from './BannerContent';
import { Box } from '@mui/material';
import { COLORS } from '@app/utils/Consts';

export const Banner: React.FC = () => {
  return (
    <>
      <section className="hero">
        <Box sx={{ display: { md: 'block', xs: 'none' } }}>
          <BannerContent />
        </Box>
      </section>
      <Box sx={{ display: { md: 'none', xs: 'block' }, backgroundColor: COLORS.primary, pt: 2 }}>
        <BannerContent />
      </Box>
    </>
  );
};
