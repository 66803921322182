/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Footer } from '../HomePages/components/Footer';
import { AcheterData } from '@app/mocks/acheterData';
import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import IonIcon from '@reacticons/ionicons';
import { CustomTextField } from '@app/components/text/CustomTextField';
import { EMMENAGER_JOURS_OPTIONS, NUMBER_OPTIONS, PROPERTY_TYPES, SITUATION_OPTIONS } from '@app/utils/Consts';
import { CustomSelect } from '@app/components/select/CustomSelect';
import { useNavigate } from 'react-router-dom';
import { createAsyncAcheter } from '@app/store/slices/contactsSlice';
import { useDispatch } from 'react-redux';
import { Snackbar } from '@mui/joy';
import { AcheterModel } from '@app/model/ContactModel';
import { LoaderModal } from '@app/components/LoaderModal';
import { displayInputValue } from '@app/utils/utils';

const AcheterPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifMessage, setNotifMessage] = useState('');
  const [formData, setFormData] = useState<AcheterModel>({
    surname: '',
    name: '',
    email: '',
    address: '',
    postalcode: '',
    phonenumber: 0,
    town: '',
    message: '',
    typePropriete: '',
    salleBain: 0,
    budgetMax: 0,
    situationActuelle: '',
    numChambre: 0,
    region: '',
    jourEmmenagez: '',
  });

  const callback = () => {
    setNotifMessage(t('save_success'));
    setLoading(false);
    setOpen(true);
    resetFields();
  };
  const onError = () => {
    setNotifMessage(t('error_message'));
    setLoading(false);
    setOpen(true);
  };

  const resetFields = () => {
    setFormData({
      surname: '',
      name: '',
      email: '',
      address: '',
      postalcode: '',
      phonenumber: 0,
      town: '',
      message: '',
      typePropriete: '',
      salleBain: 0,
      budgetMax: 0,
      situationActuelle: '',
      numChambre: 0,
      region: '',
      jourEmmenagez: '',
    });
    window.scrollTo(0, 0);
  };

  const onChangeText = (valKey: string, text: string) => {
    setFormData({ ...formData, [valKey]: text });
  };

  const onSave = () => {
    if (
      formData.surname === '' ||
      formData.name === '' ||
      formData.email === '' ||
      formData.address === '' ||
      formData.postalcode === '' ||
      formData.phonenumber === 0 ||
      formData.town === '' ||
      formData.message === '' ||
      formData.typePropriete === '' ||
      formData.salleBain === 0 ||
      formData.budgetMax === 0 ||
      formData.situationActuelle === '' ||
      formData.numChambre === 0 ||
      formData.region === '' ||
      formData.jourEmmenagez === ''
    ) {
      setNotifMessage(t('error_fields'));
      setOpen(true);
      return;
    }
    setLoading(true);
    dispatch(createAsyncAcheter(formData, callback, onError));
  };

  const goToContact = () => {
    navigate('/contact');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle>{t('acheter')}</PageTitle>
      <Header />
      <main className="greybg">
        <div className="overlay-container" style={{ background: "url('/sale.avif') center/cover" }}>
          <div className="overlay-header">
            <h2 className="h2 section-title">{'Acheter une propriété'}</h2>
          </div>
        </div>
        <section className="container">
          <Stack alignItems={'center'} my={10}>
            <Typography variant="body1" width={{ md: '50%', xs: '100%' }} textAlign={'center'}>
              {`L'achat d'une propriété est l'un des projets les plus importants de votre vie, qui se réalise en plusieurs
              étapes! De nombreuses questions importantes sont donc à considérer. Le choix d'un courtier vous offre bien
              plus qu'un soutien lors de la transaction! Ce choix vous assure un accompagnement personnalisé à chaque
              étape du processus, une prise en compte de votre réalité, et des protections privilgiées rattachées à
              votre courtier.`}
            </Typography>
          </Stack>
        </section>
        <section className="acheter-why">
          <br />
          <Stack alignItems={'center'}>
            <h2 className="section-title">{"Pourquoi nous confier votre projet d'achat d'une propriété?"}</h2>
          </Stack>
          <Grid
            container
            sx={{
              p: 5,
              pb: 9,
            }}
            spacing={5}
            className="acheter-why"
          >
            {AcheterData.map((data: any, i) => (
              <Grid key={i} xs={12} md={4} item>
                <Card sx={{ height: { md: 330, xs: 'auto' } }}>
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Stack alignItems={'center'} mb={2}>
                      <Stack className="icon" alignItems={'center'}>
                        <IonIcon name={data.icon} size="large" />
                      </Stack>
                    </Stack>
                    <Typography gutterBottom variant="h4" fontWeight={600} component="div">
                      {data.title}
                    </Typography>
                    <Typography variant="body2" alignItems={'center'} color="text.secondary">
                      {data.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </section>
        {/* <Stack alignItems={'center'} my={5}>
          <div className="service-card acheter-service-card">
            <Typography
              className="section-title"
              fontSize={{ md: '1.8rem', xs: '1rem' }}
              fontWeight={600}
              textAlign={'center'}
            >
              {`Communiquez avec nous dès le début de votre projet d'achat afin qu'on vous aide à faire de ce projet une réalité!`}
            </Typography>
          </div>
        </Stack> */}

        <Box
          my={10}
          className="overlay-container"
          style={{ background: "url('contact.jpg') center/cover", height: '250px' }}
        >
          <div className="overlay-header" style={{ background: 'rgba(112, 123, 177, 0.7)' }}>
            <Stack width={{ md: '50%', xs: '100%' }} alignItems={'center'}>
              <h2 className="h4 section-title">{`Communiquez avec nous dès le début de votre projet d'achat afin qu'on vous aide à faire de ce projet une réalité!`}</h2>
              <Stack width={{ md: '30%', xs: '60%' }}>
                <button onClick={goToContact} className="btn center">
                  {t('contactme')}
                </button>
              </Stack>
            </Stack>
          </div>
        </Box>

        <Grid container className="container" spacing={5} mb={5}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" fontWeight={550} mb={1}>{`Information Personnelle`}</Typography>
            <p className="text-danger">{`Veuillez nous fournir vos coordonnées en remplissant 
            ce formulaire et nous vous contacterons dès que possible.`}</p>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <CustomTextField
                title="Prénom"
                value={formData.surname}
                onChange={(val: string) => onChangeText('surname', val)}
              />
              <CustomTextField
                title="Courriel"
                value={formData.email}
                onChange={(val: string) => onChangeText('email', val)}
              />
              <CustomTextField
                title="Adresse"
                value={formData.address}
                onChange={(val: string) => onChangeText('address', val)}
              />
              <CustomTextField
                title="Code postal"
                value={formData.postalcode}
                onChange={(val: string) => onChangeText('postalcode', val)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <CustomTextField
                title="Nom"
                value={formData.name}
                onChange={(val: string) => onChangeText('name', val)}
              />
              <CustomTextField
                title="Téléphone"
                value={displayInputValue(formData.phonenumber)}
                onChange={(val: string) => onChangeText('phonenumber', val.replace(/[^0-9]/g, ''))}
              />
              <CustomTextField
                title="Ville"
                value={formData.town}
                onChange={(val: string) => onChangeText('town', val)}
              />
            </Stack>
          </Grid>
        </Grid>

        <Grid container className="container" spacing={5} mb={5}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" fontWeight={550} mb={1}>{`Caractéristiques de la maison recherchée`}</Typography>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <CustomSelect
                title="Type de propriété"
                translate
                value={formData.typePropriete}
                onChange={(val: string) => onChangeText('typePropriete', val)}
                options={PROPERTY_TYPES}
              />
              <CustomSelect
                title="Nombre de salle de bains"
                value={displayInputValue(formData.salleBain)}
                onChange={(val: string) => onChangeText('salleBain', val)}
                options={NUMBER_OPTIONS}
              />
              <CustomTextField
                title="Quel est votre budget maximal?"
                value={displayInputValue(formData.budgetMax)}
                onChange={(val: string) => onChangeText('budgetMax', val.replace(/[^0-9]/g, ''))}
              />
              <CustomSelect
                title="Quel est votre situation actuelle?"
                value={formData.situationActuelle}
                translate
                onChange={(val: string) => onChangeText('situationActuelle', val)}
                options={SITUATION_OPTIONS}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <CustomSelect
                title="Nombre de chambres"
                value={displayInputValue(formData.numChambre)}
                onChange={(val: string) => onChangeText('numChambre', val)}
                options={NUMBER_OPTIONS}
              />
              <CustomTextField
                title="Dans quelle région désirez-vous acheter?"
                value={formData.region}
                onChange={(val: string) => onChangeText('region', val)}
              />
              <CustomSelect
                title="Dans combien de jours désirez-vous emménagez?"
                value={formData.jourEmmenagez}
                translate
                onChange={(val: string) => onChangeText('jourEmmenagez', val)}
                options={EMMENAGER_JOURS_OPTIONS}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              title="Message(d'autres précisions à apporter)"
              value={formData.message}
              multiline
              rows={5}
              onChange={(val: string) => onChangeText('message', val)}
            />
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={{ xs: 'center', md: 'flex-end' }}>
            <button className="btn center" onClick={onSave}>
              {t('send')}
            </button>
          </Grid>
        </Grid>
        <Snackbar
          autoHideDuration={4000}
          open={open}
          variant={'solid'}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          color={'success'}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setOpen(false);
          }}
        >
          <Typography>{notifMessage}</Typography>
        </Snackbar>
        {loading && <LoaderModal open={loading} />}
        <Footer />
      </main>
    </>
  );
};

export default AcheterPage;
