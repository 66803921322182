import axios from 'axios';
// import { AxiosError } from 'axios';
// import { ApiError } from '@app/api/ApiError';
import { BASE_URL } from '@app/utils/Consts';

export const httpAuthApi = axios.create({
  baseURL: BASE_URL,
});

export const httpApi = axios.create({
  baseURL: BASE_URL + 'v1/',
  // baseURL: process.env.REACT_APP_BASE_URL,
});

// httpAuthApi.interceptors.response.use(undefined, (error: AxiosError) => {
//   throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
// });

// httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
//   throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
// });

export interface ApiErrorData {
  message: string;
}
