import React from 'react';
import IonIcon from '@reacticons/ionicons';
import { useTranslation } from 'react-i18next';

export interface BaseCardProps {
  showMore?: boolean;
}

export const About: React.FC<BaseCardProps> = ({ showMore }) => {
  const { t } = useTranslation();

  return (
    <section className="about" id="about">
      <div className="container">
        <figure className="about-banner">
          <img src="/about-banner-1.png" alt="House interior" />

          {/* <img src="about-banner-2.jpg" alt="House interior" className="abs-img" /> */}
        </figure>

        <div className="about-content">
          <p className="section-subtitle">{t('aboutus_me')}</p>
          <h2 className="h2 section-title">Nina Tchetgna Michèle</h2>
          <p className="about-text">
            Je suis courtier immobilier résidentiel chez Vendirect. Ce sera avec dévouement que je mettrais mes
            compétences professionnelles, mon savoir faire et mes valeurs à votre service pour vous accompagner dans vos
            projets immobiliers. Mon but premier est de répondre à vos besoins en avançant avec vous à votre rythme, pas
            à pas, vers l’accomplissement de vos projets.
          </p>
          <ul className="about-list">
            <li className="about-item">
              <div className="about-item-icon">
                <IonIcon name="home-outline" className="ion-icon-header" />
              </div>

              <a href='/acheter' className="about-item-text">Achat immobilier</a>
            </li>

            <li className="about-item">
              <div className="about-item-icon">
                <IonIcon name="home-outline" className="ion-icon-header" />
              </div>

              <a href='/vendre' className="about-item-text">Vente immobilière</a>
            </li>

            <li className="about-item">
              <div className="about-item-icon">
                <IonIcon name="home-outline" className="ion-icon-header" />
              </div>

              <a href='/vendre/demande-evaluation' className="about-item-text">Évaluation immobilière</a>
            </li>

            <li className="about-item">
              <div className="about-item-icon">
                <IonIcon name="home-outline" className="ion-icon-header" />
              </div>

              <a href='/acheter/conseils' className="about-item-text">Conseil immobilier</a>
            </li>
          </ul>
          <p className="callout">{t('about_quote')}</p>
          {showMore && <a href="/about" className="btn">
            {t('know_more')}
          </a>}
        </div>
      </div>
    </section>
  );
};
