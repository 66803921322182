/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { PropertyModel } from '@app/model/PropertyModel';
import { useTranslation } from 'react-i18next';
import { completeFileDownload, formatPrice, getPropertyStatusColor } from '@app/utils/utils';

export interface Props {
  data?: PropertyModel;
}

export const PropertySimilarCard: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid xs={12} md={4} item>
        <a href={`/property/${data?.uuid}`}>
          <img
            src={completeFileDownload(data?.images[0]?.downloadUrl)}
            alt={data?.title}
            style={{ width: '100%', height: 150 }}
          />
        </a>
      </Grid>
      <Grid xs={12} md={8} item>
        <Box pl={1}>
          <Stack direction={'row'} mb={1} justifyContent={'space-between'}>
            <Typography color={'white'} sx={{ backgroundColor: getPropertyStatusColor(data?.status || 'RENT'), borderRadius: 1, px: 1 }}>
              {t(data?.status || 'RENT')}
            </Typography>
            <Typography>{formatPrice(data?.price || 0)}</Typography>
          </Stack>
          <Typography fontWeight={600}>{data?.title}</Typography>
          <Typography fontWeight={200}>{`Piéces: ${data?.numPiece} Salles de bain: ${data?.numSalleBain}`}</Typography>
          <Stack alignItems={'flex-end'}>
            <button className="btn" style={{ padding: 8, borderRadius: 5 }}>
              Détails
            </button>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};
