/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import { Stack } from '@mui/material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import React, { useState } from 'react';

export interface Props {
  open: boolean;
  docUrl: string;
  onClose?: () => void;
}

export const FilePreviewModal: React.FC<Props> = ({ open, docUrl, onClose }) => {
  const [loading, setLoading] = useState(true);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog sx={{ width: '50%' }}>
        <ModalClose />
       <Stack mt={4} sx={{height: '80vh'}}>
       <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          {loading ? (
            <p style={{ textAlign: 'center' }}>
              {"Merci de patienter l'affichage du document peut prendre quelques minutes"}
            </p>
          ) : null}
          <Viewer fileUrl={docUrl} onDocumentLoad={() => setLoading(false)} />
        </Worker>
       </Stack>
      </ModalDialog>
    </Modal>
  );
};
