import React from 'react';
import { SxProps, TextField } from '@mui/material';

interface Props {
  title: string;
  value?: string;
  type?: string;
  containerStyle?: SxProps;
  required?: boolean;
  error?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
}

export const CustomTextField: React.FC<Props> = ({
  title,
  type,
  value,
  disabled,
  containerStyle,
  onChange,
  multiline,
  rows,
}) => {
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(ev.target.value);
  };

  return (
    <TextField
      id="outlined"
      label={title}
      type={type}
      value={value}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      onChange={handleChange}
      sx={{ backgroundColor: 'white', ...containerStyle }}
      fullWidth
    />
  );
};
