import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering

import { withLoading } from '@app/hocs/withLoading.hoc';
import HomePage from '@app/pages/HomePages/HomePage';
import AboutusPage from '@app/pages/AboutusPages/AboutusPage';
import BlogPage from '@app/pages/BlogPages/BlogPage';
import ContactPage from '@app/pages/ContactPages/ContactPage';
import PropertyPage from '@app/pages/PropertyPages/PropertyPage';
import BlogDetailPage from '@app/pages/BlogPages/BlogDetailPage';
import AcheterPage from '@app/pages/AcheterPages/AcheterPage';
import VendrePage from '@app/pages/VendrePages/VendrePage';
import DemandeEvaluationPage from '@app/pages/VendrePages/DemandeEvaluationPage';
import InvestirPage from '@app/pages/InvestirPages/InvestirPage';
import ConseilVendeurPage from '@app/pages/VendrePages/ConseilVendeurPage';
import ConseilAcheteurPage from '@app/pages/AcheterPages/ConseilAcheteurPage';
import CalculatricePage from '@app/pages/AcheterPages/CalculatricePage';
import VoletSocialPage from '@app/pages/VoletSocialPages/VoletSocialPage';
import FaqsPage from '@app/pages/FaqsPages/FaqsPage';
import PropertyDetailPage from '@app/pages/PropertyPages/PropertyDetailPage';
import { useDispatch } from 'react-redux';
import { listAsyncProperties } from '@app/store/slices/propertySlice';
import { listAsyncConseils } from '@app/store/slices/conseilSlice';
import { listAsyncVoletSocials } from '@app/store/slices/voletSocialSlice';

const Home = withLoading(HomePage);
const Aboutus = withLoading(AboutusPage);
const Blog = withLoading(BlogPage);
const Contact = withLoading(ContactPage);
const Property = withLoading(PropertyPage);
const Acheter = withLoading(AcheterPage);
const Vendre = withLoading(VendrePage);
const DemandeEvaluation = withLoading(DemandeEvaluationPage);
const Investir = withLoading(InvestirPage);
const ConseilVendeur = withLoading(ConseilVendeurPage);
const ConseilAcheteur = withLoading(ConseilAcheteurPage);
const Calculatrice = withLoading(CalculatricePage);
const VoletSocial = withLoading(VoletSocialPage);
const Faqs = withLoading(FaqsPage);

export const AppRouter: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listAsyncProperties());
    dispatch(listAsyncConseils());
    dispatch(listAsyncVoletSocials());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetailPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/property" element={<Property />} />
        <Route path="/property/:id" element={<PropertyDetailPage />} />
        <Route path="/acheter" element={<Acheter />} />
        <Route path="/acheter/conseils" element={<ConseilAcheteur />} />
        <Route path="/acheter/calculatrice" element={<Calculatrice />} />
        <Route path="/vendre" element={<Vendre />} />
        <Route path="/vendre/demande-evaluation" element={<DemandeEvaluation />} />
        <Route path="/vendre/conseils" element={<ConseilVendeur />} />
        <Route path="/investir" element={<Investir />} />
        <Route path="/volet-social" element={<VoletSocial />} />
        <Route path="/faqs" element={<Faqs />} />
      </Routes>
    </BrowserRouter>
  );
};
