/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { Callback } from '../store';
import { AcheterModel, CalculatriceModel, ContactModel, DemandeEvalModel } from '@app/model/ContactModel';
import { createAcheterApi, createCalculatriceApi, createContactApi, createDemandeEvalApi } from '@app/api/contact.api';

const initialState: Record<string, ContactModel> = {};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
});

export const createAsyncContact =
  (data: ContactModel, callback: Callback, onError: Callback) => async () => {
    try {
      createContactApi(data)
        .then(() => {
          callback?.();
        })
        .catch((error) => {
          console.log('create contact error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const createAsyncDemandeEval =
  (data: DemandeEvalModel, callback: Callback, onError: Callback) => async () => {
    try {
      createDemandeEvalApi(data)
        .then(() => {
          callback?.();
        })
        .catch((error) => {
          console.log('create demande eval error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const createAsyncAcheter =
  (data: AcheterModel, callback: Callback, onError: Callback) => async () => {
    try {
      createAcheterApi(data)
        .then(() => {
          callback?.();
        })
        .catch((error) => {
          console.log('create acheter error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const createAsyncCalculatrice =
  (data: CalculatriceModel, callback: Callback, onError: Callback) => async () => {
    try {
      createCalculatriceApi(data)
        .then(() => {
          callback?.();
        })
        .catch((error) => {
          console.log('create calculatrice error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export default contactsSlice.reducer;
