/* eslint-disable @typescript-eslint/no-explicit-any */
import { httpApi } from '@app/api/http.api';
import { AcheterModel, CalculatriceModel, ContactModel, DemandeEvalModel } from '@app/model/ContactModel';

export const createContactApi = (payload: ContactModel): Promise<ContactModel> =>
  httpApi.post<ContactModel>('contacts', payload).then(({ data }: any) => data);

export const createDemandeEvalApi = (payload: DemandeEvalModel): Promise<DemandeEvalModel> =>
  httpApi.post<DemandeEvalModel>('demande-evaluation', payload).then(({ data }: any) => data);

export const createAcheterApi = (payload: AcheterModel): Promise<AcheterModel> =>
  httpApi.post<AcheterModel>('acheter', payload).then(({ data }: any) => data);

export const createCalculatriceApi = (payload: CalculatriceModel): Promise<CalculatriceModel> =>
  httpApi.post<CalculatriceModel>('calculatrices', payload).then(({ data }: any) => data);
