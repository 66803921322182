/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import IonIcon from '@reacticons/ionicons';
import { useTranslation } from 'react-i18next';
import { HeaderMenu } from './HeaderMenu';
import { Stack } from '@mui/material';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

    /**
     * element toggle function
     */

  const elemToggleFunc = function (elem: any) {
    elem.classList.toggle('active');
  };

  useEffect(() => {

    /**
     * navbar toggle
     */

    const navbar = document.querySelector('[data-navbar]');
    const overlay = document.querySelector('[data-overlay]');
    const navCloseBtn = document.querySelector('[data-nav-close-btn]');
    const navOpenBtn = document.querySelector('[data-nav-open-btn]');
    const navbarLinks = document.querySelectorAll('[data-nav-link]');

    const navElemArr = [overlay, navCloseBtn, navOpenBtn] as any;

    /**
     * close navbar when click on any navbar link
     */

    for (let i = 0; i < navbarLinks.length; i++) {
      navElemArr.push(navbarLinks[i]);
    }

    /**
     * add event on all elements for toggling navbar
     */

    for (let i = 0; i < navElemArr.length; i++) {
      navElemArr[i]?.addEventListener('click', function () {
        elemToggleFunc(navbar);
        elemToggleFunc(overlay);
      });
    }

    /**
     * header active state
     */

    const header = document.querySelector('[data-header]') as any;

    window.addEventListener('scroll', function () {
      window.scrollY >= 400 ? header.classList.add('active') : header.classList.remove('active');
    });
  }, []);

  return (
    <header className="header" data-header>
      <div className="overlay" data-overlay></div>
      <div className="header-bottom">
        <div className="container">
          <a href="/" className="logo">
            <img src="/logo.png" alt="Nina Tchetgna logo" />
          </a>

          <Stack className="navbar" data-navbar>

            <div className="navbar-bottom">
              <ul className="navbar-list">
                <li>
                  <div className="dropdown">
                    <a href="/acheter" className="navbar-link center" data-nav-link>
                      {t('acheter')} <IonIcon name="caret-down-outline" style={{ fontSize: 14 }} />
                    </a>
                    <div className="dropdown-content">
                      <a href="/acheter">{t('acheter')}</a>
                      <a href="/acheter/calculatrice">{t('calculatrice_hypothecaire')}</a>
                      <a href="/acheter/conseils">{t('conseil_acheteur')}</a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="dropdown">
                    <a href="/vendre" className="navbar-link center" data-nav-link>
                      {t('vendre')} <IonIcon name="caret-down-outline" style={{ fontSize: 14 }} />
                    </a>
                    <div className="dropdown-content">
                      <a href="/vendre">{t('vendre')}</a>
                      <a href="/vendre/demande-evaluation">{t('demande_evaluation')}</a>
                      <a href="/vendre/conseils">{t('conseil_vendeur')}</a>
                    </div>
                  </div>
                </li>
                <li>
                  <a href="/investir" className="navbar-link" data-nav-link>
                    {t('investir')}
                  </a>
                </li>
                <li>
                  <a href="/property" className="navbar-link" data-nav-link>
                    {t('my_properties')}
                  </a>
                </li>
                <li>
                  <a href="/volet-social" className="navbar-link" data-nav-link>
                    {t('volet_social')}
                  </a>
                </li>
                <li>
                  <button aria-label="Menu" onClick={toggleMenu}>
                    <IonIcon name="menu" size="large" />
                  </button>
                </li>
              </ul>
            </div>
          </Stack>

        </div>
      </div>
      <HeaderMenu open={open} onClose={toggleMenu} />
    </header>
  );
};
