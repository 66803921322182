import { getYears } from './utils';

export const BASE_URL = 'https://server.ninatchetgna.com/api/';
// export const BASE_URL = 'http://localhost:8686/api/';
export const PROPERTY_TYPES: string[] = [
  'MAISON_ETAGE',
  'MAISON_ETAGE_DEMI',
  'MAISON_MOBILE',
  'MAISON_PALIER_MULTIPLE',
  'MAISON_PLAIN_PIED',
  'DUPLEX',
  'TRIPLEX',
  'QUADRUPLEX',
  'QUINTUPLEX',
  'COMMERCIALE',
  'INDUSTRIELLE',
  'ENTREPRISE',
  'FERME',
  'FERMETTE',
  'APPARTEMENT',
  'LOFT_STUDIO',
  'MAISON',
  'CONDO',
  'AUTRE',
];
export const PROPERTY_GENRE = ['APPARTMENT', 'CONDO', 'APPARTMENT_CONDO'];
export const PROPERTY_PIECES = [
  'CUISINE',
  'SALON',
  'SALLE_MANGER',
  'SALLE_BAIN',
  'SALLE_LAVAGE',
  'HALL_ENTRE',
  'HALL_ENTRE_VESTIBULE',
  'CHAMBRE_COUCHER',
  'BUREAU',
];
export const PROPERTY_REVETEMENT_SOL = ['CERAMIQUE', 'PLANCHER_FLOTANT', 'BOIS', 'BETON', 'TAPIS'];
export const PROPERTY_TYPE_COPROPRIETE = ['DEVISE'];
export const PROPERTY_EAU = ['MUNICIPALITE'];
export const PROPERTY_FENETRE = ['PVC'];
export const PROPERTY_GARAGE = ['ATTACHE', 'DETACHE', 'CHAUFFE'];
export const PROPERTY_PROXIMITE = [
  'CEGEP',
  'GOLF',
  'AUTOROUTE',
  'AUTOROUTE_VOIE_RAPIDE',
  'PISTE_RECYCLABLE',
  'TRANSPORT_COMMUN',
  'UNIVERSITE',
  'ECOLE_PRIMAIRE',
  'ECOLE_SECONDAIRE',
];
export const PROPERTY_PERMISSION = [
  'ANIMAUX_PERMIS',
  'ANIMAUX_PAS_PERMIS',
  'FUMEUR',
  'NON_FUMEUR',
  'LOCATION_COURT_TERME_PERMIS',
  'LOCATION_COURT_TERME_PAS_PERMIS',
  'LOCATION_LONG_TERME_PERMIS',
  'LOCATION_LONG_TERME_PAS_PERMIS',
];
export const PROPERTY_YEARS = getYears();
export const COLORS = {
  primary: '#707BB1',
};
export const NUMBER_OPTIONS: string[] = ['1', '2', '3', '4', '5', '6'];
export const YES_NO_OPTIONS: string[] = ['OUI', 'NON'];
export const PISCINE_OPTIONS: string[] = ['HORS_TERRE', 'CREUSEE','NO_PISCINE'];
export const SITUATION_OPTIONS: string[] = ['PROPRIETE', 'LOCATAIRE', 'CHEZ_PARENTS', "A_ETRANGER", "AUTRE"];
export const EMMENAGER_JOURS_OPTIONS: string[] = ['JOURS_0_60', 'JOURS_60_120', 'MOIS_4_8','MOIS_8'];
export const TAUX_INTERET_OPTIONS: string[] = ['MENSUEL', 'BIHEBDOMADAIRE', 'HEBDOMADAIRE'];
export const STATUT_TYPES: string[] = ['SELL', 'RENT', 'SOLD', 'RENTED'];
