/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import IonIcon from '@reacticons/ionicons';
import { PropertyModel } from '@app/model/PropertyModel';
import { completeFileDownload, formatPrice, getPropertyStatusColor } from '@app/utils/utils';
import { ConstructionOutlined, MeetingRoomOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLORS } from '@app/utils/Consts';

export interface Props {
  data: PropertyModel;
}

export const PropertyCard: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <li>
      <div className="property-card">
        <figure className="card-banner">
          <a href={`/property/${data.uuid}`}>
            <img src={completeFileDownload(data.images[0]?.downloadUrl)} alt={data.title} className="w-100" />
          </a>

          <div className={`card-badge ${getPropertyStatusColor(data.status)}`}>{t(data.status)}</div>

          <div className="banner-actions">
            {/* <button className="banner-actions-btn">
              <IonIcon name="location" />
              <address>{data.address}</address>
            </button> */}

            <button className="banner-actions-btn">
              <IonIcon name="camera" />
              <span>{data.images.length}</span>
            </button>

            <button className="banner-actions-btn">
              <ConstructionOutlined fontSize="inherit" />
              <span>{data.anneeConstruction}</span>
            </button>
          </div>
        </figure>
        <Stack p={2} bgcolor={COLORS.primary}>
          <Typography color={'white'} textTransform={'uppercase'} fontWeight={600}>
            {t(data.typeBatiment)}
          </Typography>
        </Stack>
        <div className="card-content">
          {/* <div className="card-price">
            <strong>${data.price}</strong>/Mois
          </div> */}

          <h3 className="h5 card-title">
            <a className="black" href={`/property/${data.uuid}`}>
              {data.title}
            </a>
          </h3>

          {/* <p className="card-text">{stringReduceAddDots(data.description, 100)}</p> */}

          <ul className="card-list">
            <li className="card-item">
              <span>
                <strong>{formatPrice(data.price || 0)} $</strong>
              </span>
            </li>

            <Stack direction={'row'} alignItems={'flex-end'}>
              <li className="card-item">
                <span>
                  <IonIcon className="ion-icon" name="bed-outline" />
                  <strong>{data.numChambre}</strong>
                </span>
              </li>

              <li className="card-item">
                <span>
                  <MeetingRoomOutlined className="ion-icon" />
                  <strong>{data.numSalleBain}</strong>
                </span>
              </li>
            </Stack>

            {/* <li className="card-item">
              <strong>{data.superficieTerrain}</strong>

              <span>
                <IonIcon name="square-outline" /> Square Ft
              </span>
            </li> */}
          </ul>
        </div>

        {/* <div className="card-footer">
          <div className="card-author">
            <figure className="author-avatar">
              <img src="author.jpg" alt="William Seklo" className="w-100" />
            </figure>

            <div>
              <p className="author-name">
                <a href="#">William Seklo</a>
              </p>

              <p className="author-title">Estate Agents</p>
            </div>
          </div>

          <div className="card-footer-actions">
            <button className="card-footer-actions-btn">
              <IonIcon name="resize-outline" />
            </button>

            <button className="card-footer-actions-btn">
              <IonIcon name="heart-outline" />
            </button>

            <button className="card-footer-actions-btn">
              <IonIcon name="add-circle-outline" />
            </button>
          </div>
        </div> */}
      </div>
    </li>
  );
};
