/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Footer } from '../HomePages/components/Footer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { FaqsData } from '@app/mocks/faqsData';

const FaqsPage: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle>{t('faqs')}</PageTitle>
      <Header />
      <main className="greybg">
        <div className="overlay-container" style={{ background: "url('/faq.jpg') center/cover" }}>
          <div className="overlay-header">
            <h2 className="h2 section-title">{t('faqs')}</h2>
          </div>
        </div>
        <Stack my={10} className="container">
          {FaqsData.map((val: any) => (
            <Accordion key={val.title}>
              <AccordionSummary
                // sx={{ bgcolor: COLORS.primary + '50' }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{val.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: val.description,
                  }}
                ></Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>

        <Footer />
      </main>
    </>
  );
};

export default FaqsPage;
