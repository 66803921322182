/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Box, Stack, Typography } from '@mui/material';
import { Footer } from '../HomePages/components/Footer';
import { CustomTextField } from '@app/components/text/CustomTextField';
import { CustomSelect } from '@app/components/select/CustomSelect';
import { COLORS, TAUX_INTERET_OPTIONS } from '@app/utils/Consts';
import { Snackbar } from '@mui/joy';
import { CalculatriceModel } from '@app/model/ContactModel';
import { createAsyncCalculatrice } from '@app/store/slices/contactsSlice';
import { useDispatch } from 'react-redux';
import { calculateMortgage, displayInputValue } from '@app/utils/utils';

const CalKeyValue = ({ title, value }: any) => {
  return (
    <Stack mb={3}>
      <Typography color={'white'} fontWeight={800} fontSize={30}>
        $ {title}
      </Typography>
      <Typography color={'white'}>{value}</Typography>
    </Stack>
  );
};

const CalculatricePage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [notifMessage, setNotifMessage] = useState('');
  const [formData, setFormData] = useState<CalculatriceModel>({
    montant: 0,
    interet: 0,
    duree: 0,
    type: 'MENSUEL',
  });
  const [resultData, setResultData] = useState({
    monthlyPayment: 0,
    annualPayment: 0,
  });

  const callback = () => {
    // setNotifMessage(t('save_success'));
    // setOpen(true);
    resetFields();
  };
  const onError = () => {
    setNotifMessage(t('error_message'));
    setOpen(true);
  };

  const resetFields = () => {
    setFormData({
      montant: 0,
      interet: 0,
      duree: 0,
      type: 'MENSUEL',
    });
    // window.scrollTo(0, 0);
  };

  const onChangeText = (valKey: string, text: string) => {
    setFormData({ ...formData, [valKey]: text });
  };

  const onSave = () => {
    if (formData.montant > 0 && formData.interet > 0 && formData.duree > 0) {
      const loanAmount = formData.montant;
      const annualInterestRate = formData.interet;
      const loanDurationInYears = formData.duree;
      // const paymentType = formData.type;

      const result = calculateMortgage(loanAmount, annualInterestRate, loanDurationInYears);
      setResultData(result);

      dispatch(createAsyncCalculatrice(formData, callback, onError));
    }
  };

  const getMonthlyValue = (val: number) => {
    if (formData.type === 'MENSUEL') return val;
    if (formData.type === 'BIHEBDOMADAIRE') return val / 2;
    if (formData.type === 'HEBDOMADAIRE') return val / 4;
    return val;
  };

  const reset = () => {
    setResultData({
      monthlyPayment: 0,
      annualPayment: 0,
    });
    resetFields();
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle>{t('calculatrice_hypothecaire')}</PageTitle>
      <Header />
      <main className="greybg">
        <div className="overlay-container" style={{ background: "url('/calculatrice.webp') center/cover" }}>
          <div className="overlay-header">
            <h2 className="h2 section-title" style={{ marginBottom: 10 }}>
              {t('calculatrice_hypothecaire')}
            </h2>
            <p className="section-title" style={{ color: 'white', fontWeight: 700, fontSize: 12 }}>
              {t('calculatrice_hypothecaire_desc')}
            </p>
          </div>
        </div>
        <Stack alignItems={'center'} my={5}>
          <div style={{ textAlign: 'left' }} className="service-card acheter-service-card">
            <Stack spacing={3} mb={5}>
              <CustomTextField
                title="Montant total de l'hypothèque ($)"
                value={displayInputValue(formData.montant)}
                onChange={(val: string) => onChangeText('montant', val)}
              />
              <CustomTextField
                title="Taux d'intérêt (%)"
                value={displayInputValue(formData.interet)}
                onChange={(val: string) => onChangeText('interet', val)}
              />
              <CustomTextField
                title="Amortissement (années)"
                value={displayInputValue(formData.duree)}
                onChange={(val: string) => onChangeText('duree', val)}
              />
              <CustomSelect
                title="Type"
                value={formData.type}
                translate
                onChange={(val: string) => onChangeText('type', val)}
                options={TAUX_INTERET_OPTIONS}
              />
              <button className="btn center" onClick={onSave}>
                {'Calculer'}
              </button>
            </Stack>
            {resultData.annualPayment !== 0 ? (
              <Box p={3} bgcolor={COLORS.primary}>
                <h2 className="h5 section-title white">
                  {'Sur base de ce que vous avez introduit, vous devrez payer:'}
                </h2>

                <CalKeyValue
                  title={getMonthlyValue(Number(resultData.monthlyPayment)).toFixed(2)}
                  value={t(`_${formData.type}`)}
                />
                <CalKeyValue title={resultData.annualPayment.toFixed(2)} value="Coût annuel du prêt" />
                <Stack direction={'row'} justifyContent={'center'}>
                  <button className="btn center" onClick={reset}>
                    {'Nouveau Calcule'}
                  </button>
                </Stack>
              </Box>
            ) : null}
          </div>
        </Stack>
        <Snackbar
          autoHideDuration={4000}
          open={open}
          variant={'solid'}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          color={'success'}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setOpen(false);
          }}
        >
          <Typography>{notifMessage}</Typography>
        </Snackbar>
        <Footer />
      </main>
    </>
  );
};

export default CalculatricePage;
