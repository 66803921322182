export const AcheterData = [
    {
        title: 'Économie',
        icon: 'wallet-outline',
        description: "Nous sommes outillés pour négocier votre propriété afin d’obtenir un prix juste. Sauvez du temps, de l’énergie et de l’argent! Bénéficiez d’un service gratuit pour l’acheteur."
    },
    {
        title: 'Protection',
        icon: 'lock-closed-outline',
        description: "Une protection exclusive est accordée aux acheteurs. Celle-ci vous procure la paix d'esprit pendant et après votre transaction. Elle vous procure une couverture en cas de délai ou de désistement, vous accorde une indemnisation des frais juridiques en cas de litige ou de vice caché, et vous offre un an de services d'assistance légale."
    },
    {
        title: 'Tranquilité',
        icon: 'accessibility-outline',
        description: "En sollicitant notre accompagnement,  vous n’avez plus à vous soucier de sa gestion ni des stratégies à adopter. Vous faites confiance à un courtier compétent qui prend à coeur vos intérêts!"
    },
];