import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Footer } from '../HomePages/components/Footer';
import { About } from '../HomePages/components/About';

const AboutusPage: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <PageTitle>{t('aboutus_me')}</PageTitle>
      <Header />
      <main className='greybg'>
        <article>
          <About />
        </article>
        <Footer />
      </main>
    </>
  );
};

export default AboutusPage;
