/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import IonIcon from '@reacticons/ionicons';
import { useTranslation } from 'react-i18next';
import { renameFileDownload, stringReduceAddDots } from '@app/utils/utils';
import moment from 'moment';
import { BlogModel } from '@app/model/BlogModel';

export interface Props {
  data: BlogModel;
}

export const BlogCard: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <li>
      <div className="blog-card">
        <figure className="card-banner">
          <img src={renameFileDownload(data.imageUrl || '')} alt="Blog title" className="w-100" />
        </figure>

        <div className="blog-content">
          <div className="blog-content-top">
            {/* <ul className="card-meta-list">
              <li>
                <a href="#" className="card-meta-link">
                  <IonIcon name="person" className="ion-icon-header" />
                  <span>by: Admin</span>
                </a>
              </li>

              <li>
                <a href="#" className="card-meta-link">
                  <IonIcon name="pricetags" className="ion-icon-header" />
                  <span>Interior</span>
                </a>
              </li>
            </ul> */}

            <h3 className="h3 blog-title">
              <a href={data?.link ? data?.link : `/blog/${data?.uuid}`}>{stringReduceAddDots(data.title, 80)}</a>
            </h3>
          </div>

          <div className="blog-content-bottom">
            <div className="publish-date">
              <IonIcon name="calendar" className="ion-icon-header" />
              <time dateTime="2022-27-04">{moment(data?.createdAt || 1 * 1000)
                  .toDate()
                  .toLocaleDateString('fr-FR')}</time>
            </div>

            <a href={data?.link ? data?.link : `/blog/${data?.uuid}`} className="read-more-btn">
              {t('read_more')}
            </a>
          </div>
        </div>
      </div>
    </li>
  );
};
