/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Footer } from '../HomePages/components/Footer';
import { BlogCard } from '@app/components/BlogCard';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { listAsyncBlogs } from '@app/store/slices/blogSlice';
import { useAppSelector } from '@app/hooks/reduxHooks';

const BlogPage: React.FC = () => {
  const blogs = useAppSelector((state) => state.blog);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(listAsyncBlogs());
  }, [dispatch]);

  return (
    <>
      <PageTitle>{t('blog')}</PageTitle>
      <Header />
      <main className="greybg">
        <div className="overlay-container" style={{ background: "url('/blog.jpg') center/cover" }}>
          <div className="overlay-header">
            <h2 className="h2 section-title">{"Quelques articles sur l'immobilier"}</h2>
          </div>
        </div>
        <section className="blog">
          <div className="container">
            <Grid container spacing={5}>
              {blogs.map((data: any, i) => (
                <Grid item key={i} md={4} xs={12}>
                  <BlogCard data={data} />
                </Grid>
              ))}
            </Grid>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default BlogPage;
