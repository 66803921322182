/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice } from '@reduxjs/toolkit';
import { ConseilModel } from '@app/model/ConseilModel';
import { createConseilApi, getConseilsApi, updateConseilApi } from '@app/api/conseil.api';
import { AppDispatch, Callback } from '../store';

const initialState: Record<string, ConseilModel> = {};

export const conseilSlice = createSlice({
  name: 'conseil',
  initialState,
  reducers: {
    addConseil: (state, action) => {
      state[action.payload.uuid] = action.payload;
      return state;
    },
    listConseil: (state, action) => {
      state = {};
      for (const obj of action.payload) {
        state[obj.uuid] = obj;
      }
      return state;
    },
    removeConseil: (state, action) => {
      delete state[action.payload];
      return state;
    },
    updateConseil: (state, action) => {
      state[action.payload.uuid] = {
        ...state[action.payload.uuid],
        ...action.payload,
      };
      return state;
    },
  },
});

const { addConseil, updateConseil, listConseil } = conseilSlice.actions;

export const createAsyncConseil =
  (data: ConseilModel, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      createConseilApi(data)
        .then((res) => {
          dispatch(addConseil(res));
          callback?.(data.type);
        })
        .catch((error) => {
          console.log('create conseil error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const updateAsyncConseil =
  (data: ConseilModel, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      updateConseilApi(data)
        .then((res) => {
          dispatch(updateConseil(res));
          callback?.(data.type);
        })
        .catch((error) => {
          console.log('update conseil error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const listAsyncConseils = () => async (dispatch: AppDispatch) => {
  try {
    const res = await getConseilsApi();
    dispatch(listConseil(res));
  } catch (error) {
    console.log('list conseils error', error);
  }
};

export default conseilSlice.reducer;
