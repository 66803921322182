/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ValeurData } from '@app/mocks/valeurData';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const MyValues: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="service" id="service">
      <div className="container">
        <Typography className="section-subtitle" sx={{ textAlign: 'center', alignItems: 'center', fontSize: {md: '22px!important', xs: '12px!important'} }}>
          {t('about_quote')}
        </Typography>

        <Typography className="section-title" fontWeight={600} sx={{ fontSize: { md: '30px', xs: '20px' } }}>
          Mes Valeurs... pour BATIR une collaboration durable
        </Typography>

        <Grid container spacing={3}>
          {ValeurData.map((data: any, i) => (
            <Grid item key={i} md={6} xs={12}>
              <div
                data-aos="fade-up"
                className="service-card h-100"
                style={{ justifyContent: 'flex-end', textAlign: 'left', border: 'none' }}
              >
                {/* <div className="card-icon">
                  <img src="/service-1.png" alt="Service icon" />
                </div> */}

                {/* <h3 className="h3 card-title">
                  <a href="#">{data.title}</a>
                </h3> */}

                <p className="cfirst">{data.description}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
};
