/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Footer } from '../HomePages/components/Footer';
import { Box, Stack, Typography } from '@mui/material';
import { useAppSelector } from '@app/hooks/reduxHooks';
import IonIcon from '@reacticons/ionicons';
import { VoletSocialModel } from '@app/model/VoletSocialModel';
import { ensureHttps, renameFileDownload } from '@app/utils/utils';

const VoletSocialPage: React.FC = () => {
  const voletSocials = useAppSelector((state) => Object.values(state.voletSocial));
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle>{t('volet_social')}</PageTitle>
      <Header />
      <main className="greybg">
        <div className="overlay-container" style={{ background: "url('/social.jpg') center/cover" }}>
          <div className="overlay-header">
            <h2 className="h2 section-title">{t('volet_social')}</h2>
          </div>
        </div>
        <Box mb={10} className="container">
          <Stack alignItems={'center'} my={10} mb={7}>
            <Typography variant="body1" mb={3} width={{ md: '70%', xs: '100%' }} textAlign={'center'}>
              {`Que votre lumière brille devant les hommes afin qu'ils voient votre belle manière d’agir et qu’ainsi ils célèbrent la gloire de votre Père céleste. Math 5:16`}
            </Typography>
            <Typography variant="body1" width={{ md: '70%', xs: '100%' }} textAlign={'center'}>
              {`Chaque action de bonté et d'amour envers notre prochain contribue à faire de notre planète un monde où il fait beau vivre. Les enfants et les femmes sont, depuis la nuit des temps, une cible vulnérable, et ceci, dans toutes les sociétés! C'est pourquoi nous avons une grande sensibilité face à toutes les initiatives qui touchent les enfants ainsi que les femmes.`}
            </Typography>
          </Stack>
          {voletSocials.length ? <h2 className="h2 section-title" style={{marginBottom: 0}}>{'Soutien aux organismes'}</h2> : null}
          {voletSocials.length ? (
            <Typography variant="body1" fontSize={14} textAlign={'center'} my={3} mb={5}>
              {`Si ces causes vous rejoignent, aller sur les sites ci-dessous pour les soutenir.`}
            </Typography>
          ) : null}
          <ul className="features-list">
            {voletSocials.map((data: VoletSocialModel) => (
              <li key={data.uuid}>
                <a href={ensureHttps(data.link)} target="_blank" rel="noreferrer" className="features-card">
                  <img src={renameFileDownload(data.imageUrl || '')} alt="programme" className="features-img" />

                  <h3 className="card-title">{data.title}</h3>

                  <div className="card-btn">
                    <IonIcon name="arrow-forward-outline" className="ion-icon-header" />
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </Box>

        <Footer />
      </main>
    </>
  );
};

export default VoletSocialPage;
