import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Banner } from './components/Banner';
import { Footer } from './components/Footer';
import { Programme } from './components/Programme';
import { MyValues } from './components/MyValues';
import { Temoignage } from './components/Temoignage';
import { Mission } from './components/Mission';

const DashboardPage: React.FC = () => {

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <PageTitle>{t('home')}</PageTitle>
      <Header />
      <main>
        <article>
          <Banner />
          <MyValues />
          <Mission />
          {/* <Property /> */}
          <Temoignage />
          <Programme />
          {/* <Blog />
           */}
        </article>
        {/* <MessengerCustomerChat pageId="573264413334148" appId="1206026023689564" /> */}
        <Footer />
      </main>
    </>
  );
};

export default DashboardPage;
