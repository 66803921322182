import propertyReducer from '@app/store/slices/propertySlice';
import blogReducer from '@app/store/slices/blogSlice';
import voletSocialReducer from '@app/store/slices/voletSocialSlice';
import conseilReducer from '@app/store/slices/conseilSlice';

export default {
  property: propertyReducer,
  blog: blogReducer,
  voletSocial: voletSocialReducer,
  conseil: conseilReducer,
};
