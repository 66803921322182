import React, { CSSProperties } from 'react';
import { Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  value?: string;
  direction?: 'row' | 'column';
  titleStyle?: CSSProperties;
  valueStyle?: CSSProperties;
}

export const CustomKeyValue: React.FC<Props> = ({ title, direction = 'row', value, titleStyle, valueStyle }) => {
  return (
    <Stack direction={direction} spacing={1}>
      <Typography sx={{ fontWeight: 600, ...titleStyle }}>{title}</Typography>
      <Typography sx={{ ...valueStyle }}>{value || ''}</Typography>
    </Stack>
  );
};
