export const ValeurData = [
  {
    title: 'Besoins',
    description: "Besoins (Je suis à l’écoute des besoins de mon client et  je centre toutes mes démarches sur ses besoins)",
  },
  {
    title: 'Attente',
    description: "Attente (Je prends en note les attentes de mon client et lui expose les miennes afin de favoriser une communication et une collaboration reussie)",
  },
  {
    title: 'Transparence',
    description: "Transparence (J'agis dans une totale transparence durant tout le processus, faisant une priorité d'informer mon client en tout temps)",
  },
  {
    title: 'Intégrité',
    description: "Intégrité (les intérêts de mon client passent TOUJOURS en premier)",
  },
  {
    title: 'Responsabilité',
    description: "Responsabilité (Je suis responsable de mes gestes professionnels et m’engage à protéger en tout temps mon client)",
  },
];
