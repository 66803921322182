/* eslint-disable @typescript-eslint/no-explicit-any */
import { httpApi } from '@app/api/http.api';
import { PropertyModel } from '@app/model/PropertyModel';

export const getPropertiesApi = (): Promise<PropertyModel[]> =>
  httpApi.get<PropertyModel[]>('properties').then(({ data }: any) => data);

export const getPropertyApi = (uuid: string): Promise<PropertyModel> =>
  httpApi.get<PropertyModel>(`properties/${uuid}`).then(({ data }: any) => data);

export const getSimilarPropertyApi = (uuid: string): Promise<PropertyModel[]> =>
  httpApi.get<PropertyModel[]>(`properties/similar/${uuid}`).then(({ data }: any) => data);
