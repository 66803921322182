/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  value?: string;
  options: string[];
  translate?: boolean;
  onChange?: (value: any) => void;
}

export const CustomSelect: React.FC<Props> = ({ title, value, onChange, options, translate }) => {
  
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    onChange?.(event.target.value as string);
  };
  
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{title}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={title}
        onChange={handleChange}
        sx={{backgroundColor: 'white'}}
      >
        {options.map((option: string, i) => (
          <MenuItem key={i} value={option}>
            {translate ? t(option) : option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
