/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress, Modal, Stack } from '@mui/joy';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  open: boolean;
  onClose?: ()=> void;
}

export const LoaderModal: React.FC<Props> = ({ open, onClose }) => {

  const { t } = useTranslation();

  return (
    <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={onClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Stack alignItems={'center'} justifyContent={'center'} sx={{bgcolor: 'white', height: 150, width: 350}}>
        <CircularProgress size="lg" />
        <Typography mt={3}>{t('loading')}</Typography>
        </Stack>
      </Modal>
  );
};
