/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/PageTitle/PageTitle';
import { Header } from '@app/components/header/Header';
import { Grid, Stack, Typography } from '@mui/material';
import { CustomTextField } from '@app/components/text/CustomTextField';
import { Footer } from '../HomePages/components/Footer';
import { CustomSelect } from '@app/components/select/CustomSelect';
import { NUMBER_OPTIONS, PISCINE_OPTIONS, PROPERTY_TYPES, YES_NO_OPTIONS } from '@app/utils/Consts';
import { useDispatch } from 'react-redux';
import { createAsyncDemandeEval } from '@app/store/slices/contactsSlice';
import { Snackbar } from '@mui/joy';
import { DemandeEvalModel } from '@app/model/ContactModel';
import { LoaderModal } from '@app/components/LoaderModal';
import { displayInputValue } from '@app/utils/utils';

const DemandeEvaluationPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifMessage, setNotifMessage] = useState('');
  const [formData, setFormData] = useState<DemandeEvalModel>({
    surname: '',
    name: '',
    email: '',
    address: '',
    postalcode: '',
    phonenumber: 0,
    town: '',
    message: '',
    typePropriete: '',
    anneeConstruction: 0,
    superficie: '',
    numChambreHorsSol: 0,
    sousol: '',
    typePiscine: '',
    stationnementGarage: '',
    foyerPoele: '',
    nombrePiece: 0,
    nombreSalleEau: 0,
    nombreSalleBain: 0,
    nombreChambreSousol: 0,
  });

  const callback = () => {
    setNotifMessage(t('save_success'));
    setLoading(false);
    setOpen(true);
    resetFields();
  };
  const onError = () => {
    setNotifMessage(t('error_message'));
    setLoading(false);
    setOpen(true);
  };

  const resetFields = () => {
    setFormData({
      surname: '',
      name: '',
      email: '',
      address: '',
      postalcode: '',
      phonenumber: 0,
      town: '',
      message: '',
      typePropriete: '',
      anneeConstruction: 0,
      superficie: '',
      numChambreHorsSol: 0,
      sousol: '',
      typePiscine: '',
      stationnementGarage: '',
      foyerPoele: '',
      nombrePiece: 0,
      nombreSalleEau: 0,
      nombreSalleBain: 0,
      nombreChambreSousol: 0,
    });
    window.scrollTo(0, 0);
  };

  const onChangeText = (valKey: string, text: string) => {
    setFormData({ ...formData, [valKey]: text });
  };

  const onSave = () => {
    if (
      formData.surname === '' ||
      formData.name === '' ||
      formData.email === '' ||
      formData.address === '' ||
      formData.postalcode === '' ||
      formData.phonenumber === 0 ||
      formData.town === '' ||
      formData.message === '' ||
      formData.typePropriete === '' ||
      formData.anneeConstruction === 0 ||
      formData.superficie === '' ||
      formData.numChambreHorsSol === 0 ||
      formData.sousol === '' ||
      formData.typePiscine === '' ||
      formData.stationnementGarage === '' ||
      formData.foyerPoele === '' ||
      formData.nombrePiece === 0 ||
      formData.nombreSalleEau === 0 ||
      formData.nombreSalleBain === 0 ||
      formData.nombreChambreSousol === 0
    ) {
      setNotifMessage(t('error_fields'));
      setOpen(true);
      return;
    }
    setLoading(true);
    dispatch(createAsyncDemandeEval(formData, callback, onError));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle>{t('demande_evaluation')}</PageTitle>
      <Header />
      <main className="greybg">
        <div className="overlay-container" style={{ background: "url('/evaluation.jpeg') center/cover" }}>
          <div className="overlay-header">
            <h2 className="h2 section-title">{t('obtenez_evaluation')}</h2>
          </div>
        </div>
        <Stack alignItems={'center'} my={5}>
          <div className="service-card acheter-service-card">
            <Typography
              className="section-title"
              fontSize={{ md: '1.4rem', xs: '0.7rem' }}
              fontWeight={500}
              textAlign={'center'}
            >
              {'Remplissez ce formulaire afin que nous puissions vous contacter pour fixer un rendez-vous.'}
            </Typography>
          </div>
        </Stack>
        {/* <Stack alignItems={'center'}>
          <p className="hero-text border-none center">
            {'Remplissez ce formulaire afin que nous puisson vous contacter pour fixer un rendez-vous.'}
          </p>
        </Stack> */}

        <Grid container className="container" spacing={5} my={5}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" fontWeight={550} mb={1}>{`Information Personnelle`}</Typography>
            <p className="text-danger">{`Veuillez nous fournir vos coordonnées en remplissant 
            ce formulaire et nous vous contacterons dès que possible.`}</p>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <CustomTextField
                title="Prénom"
                value={formData.surname}
                onChange={(val: string) => onChangeText('surname', val)}
              />
              <CustomTextField
                title="Courriel"
                value={formData.email}
                onChange={(val: string) => onChangeText('email', val)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <CustomTextField
                title="Nom"
                value={formData.name}
                onChange={(val: string) => onChangeText('name', val)}
              />
              <CustomTextField
                title="Téléphone"
                value={displayInputValue(formData.phonenumber)}
                onChange={(val: string) => onChangeText('phonenumber', val.replace(/[^0-9]/g, ''))}
              />
            </Stack>
          </Grid>
        </Grid>

        <Grid container className="container" spacing={5} mb={5}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" fontWeight={550} mb={1}>{`À propos de votre propriété`}</Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              {/* <CustomTextField
                title="No civique"
                value={formData.noCivique}
                onChange={(val: string) => onChangeText('noCivique', val)}
              />
              <CustomTextField
                title="Nom de rue"
                value={formData.nomRue}
                onChange={(val: string) => onChangeText('nomRue', val)}
              /> */}
              <CustomTextField
                title="Ville"
                value={formData.town}
                onChange={(val: string) => onChangeText('town', val)}
              />
              <CustomTextField
                title="Code postal"
                value={formData.postalcode}
                onChange={(val: string) => onChangeText('postalcode', val)}
              />

              <CustomTextField
                title="Année de construction"
                value={displayInputValue(formData.anneeConstruction)}
                onChange={(val: string) => onChangeText('anneeConstruction', val)}
              />
              <CustomTextField
                title="Superficie habitable (mètres ou pieds carrés)"
                value={formData.superficie}
                onChange={(val: string) => onChangeText('superficie', val)}
              />
              <CustomSelect
                title="Nombre de salles de bain"
                value={displayInputValue(formData.nombreSalleBain)}
                onChange={(val: string) => onChangeText('nombreSalleBain', val)}
                options={NUMBER_OPTIONS}
              />
              <CustomSelect
                title="Nombre de chambre hors-sol"
                value={displayInputValue(formData.numChambreHorsSol)}
                onChange={(val: string) => onChangeText('numChambreHorsSol', val)}
                options={NUMBER_OPTIONS}
              />
              <CustomSelect
                title="Nombre de salles d'eau"
                value={displayInputValue(formData.nombreSalleEau)}
                onChange={(val: string) => onChangeText('nombreSalleEau', val)}
                options={NUMBER_OPTIONS}
              />
              <CustomSelect
                title="Nombre de chambre sous-sol"
                value={displayInputValue(formData.nombreChambreSousol)}
                onChange={(val: string) => onChangeText('nombreChambreSousol', val)}
                options={NUMBER_OPTIONS}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <CustomTextField
                title="Adresse"
                value={formData.address}
                onChange={(val: string) => onChangeText('address', val)}
              />
              <CustomSelect
                title="Type de propriété"
                value={formData.typePropriete}
                translate
                onChange={(val: string) => onChangeText('typePropriete', val)}
                options={PROPERTY_TYPES}
              />
              <CustomSelect
                title="Sous-sol"
                value={formData.sousol}
                translate
                onChange={(val: string) => onChangeText('sousol', val)}
                options={YES_NO_OPTIONS}
              />
              <CustomSelect
                title="Type de piscine"
                value={formData.typePiscine}
                translate
                onChange={(val: string) => onChangeText('typePiscine', val)}
                options={PISCINE_OPTIONS}
              />
              <CustomSelect
                title="Stationnement garage"
                value={formData.stationnementGarage}
                translate
                onChange={(val: string) => onChangeText('stationnementGarage', val)}
                options={YES_NO_OPTIONS}
              />
              <CustomSelect
                title="Foyer-poêle"
                value={formData.foyerPoele}
                translate
                onChange={(val: string) => onChangeText('foyerPoele', val)}
                options={YES_NO_OPTIONS}
              />
              <CustomSelect
                title="Nombre de pièces"
                value={displayInputValue(formData.nombrePiece)}
                onChange={(val: string) => onChangeText('nombrePiece', val)}
                options={NUMBER_OPTIONS}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              title="Message(d'autres précisions à apporter)"
              value={formData.message}
              multiline
              rows={5}
              onChange={(val: string) => onChangeText('message', val)}
            />
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={{ xs: 'center', md: 'flex-end' }}>
            <button className="btn center" onClick={onSave}>
              {t('send')}
            </button>
          </Grid>
        </Grid>
        <Snackbar
          autoHideDuration={4000}
          open={open}
          variant={'solid'}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          color={'success'}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setOpen(false);
          }}
        >
          <Typography>{notifMessage}</Typography>
        </Snackbar>
        {loading && <LoaderModal open={loading} />}
        <Footer />
      </main>
    </>
  );
};

export default DemandeEvaluationPage;
